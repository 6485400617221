import { IConfig, IToggle } from '@unleash/proxy-client-react';
import { API } from 'constants/API';

export const featureFlagConfig: IConfig = {
  url: API.PROXY || '',
  clientKey: process.env.REACT_APP_PROXY_KEY || '',
  refreshInterval: 300, // in seconds
  appName: 'default',
  environment: process.env.REACT_APP_PROXY_ENVIRONMENT,
  disableMetrics: true,
};

export const ADDITIONAL_AGREEMENTS_FEATURE_FLAG = 'GPM-24066-additional-agreements';
export const LOGISTICS_IN_PLATFORM_FEATURE_FLAG = 'GPM-29779-logistics-in-platform';
export const SHOW_GSP_ORGANIZATION_FEATURE_FLAG = 'GPM-34849-hide-gsp';

export const defaultFeatureFlags: IToggle[] = [
  {
    name: 'Bargain',
    enabled: false,
    variant: { enabled: false, name: 'disabled' },
    impressionData: false,
  },
  {
    name: ADDITIONAL_AGREEMENTS_FEATURE_FLAG,
    enabled: false,
    variant: { enabled: false, name: 'disabled' },
    impressionData: false,
  },
  {
    name: LOGISTICS_IN_PLATFORM_FEATURE_FLAG,
    enabled: false,
    variant: { enabled: false, name: 'disabled' },
    impressionData: false,
  },
  {
    name: SHOW_GSP_ORGANIZATION_FEATURE_FLAG,
    enabled: false,
    variant: { enabled: false, name: 'disabled' },
    impressionData: false,
  },
];
