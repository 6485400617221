import { PermissionStore } from './Permission.effects';
import { Role } from '../../services/Accounts/RolesService';

export interface CheckRolesRightsProps {
  currentRoles?: Role[];
  testedRoles: string | string[];
}

export const checkRolesRights = (props: CheckRolesRightsProps): boolean => {
  const { currentRoles, testedRoles } = props;

  if ((window as { stecpointDebugRole?: boolean }).stecpointDebugRole) {
    return true;
  }

  if (testedRoles instanceof Array) {
    return Boolean(testedRoles.find((item) => currentRoles && currentRoles.find((role) => role.mnemonic === item)));
  }

  return Boolean(currentRoles && currentRoles.find((role) => role.mnemonic === testedRoles));
};

export const checkHasAccess = (
  permission: Nullable<PermissionStore>,
  resourceMnemonic: string,
  actionId: number
): boolean =>
  Boolean(
    permission?.resources.find(
      (resource) =>
        resource.name === resourceMnemonic && resource.actions.find((action) => action.actionID === actionId)
    )
  );
