import { API } from 'constants/API';
import { BaseResponseResult, ResponseResult } from 'models/CommonModels';
import { PageContextRequest } from 'models/pageContext/IPageContext';
import { PagedResponse } from 'models/pageContext/PageContextResponse';
import { ServiceBase } from 'services/ServiceBase';
import { VersionAPI } from 'services/versionAPI';
import {
  BankAccount,
  BankAccountsFilter,
  CreateBankAccountRequest,
  UpdateBankAccountRequest,
} from '../models/BankAccounts';

export class BankAccounts extends ServiceBase {
  protected static BASE_URL = '/bankAccounts';

  protected static options = { baseURL: API.ORGANIZATIONS_API + VersionAPI.VERSION10 };

  public static postBankAccount(params: CreateBankAccountRequest) {
    return this.post<ResponseResult<number>>('', params, this.options);
  }

  public static putBankAccount(params: UpdateBankAccountRequest) {
    return this.put<BaseResponseResult>('', params, this.options);
  }

  public static deleteBankAccount(bankAccountId: number) {
    return this.delete<BaseResponseResult>(`/${bankAccountId}`, {}, this.options);
  }

  public static pageBankAccounts(params: PageContextRequest<BankAccountsFilter>) {
    return this.post<PagedResponse<BankAccount>>('/page', params, this.options);
  }
}
