import { API } from 'constants/API';
import { ResponseResult, UniversalShortEntity } from 'models/CommonModels';
import { ServiceBase } from 'services/ServiceBase';
import { VersionAPI } from 'services/versionAPI';

export class SpecialProjects extends ServiceBase {
  protected static BASE_URL = '/special-projects';

  protected static options = { baseURL: API.ORGANIZATIONS_API + VersionAPI.VERSION10 };

  public static getSpecialProjects() {
    return this.get<UniversalShortEntity[]>('', {}, this.options);
  }

  public static addSpecialProject(params: { name: string }) {
    return this.post<ResponseResult>('', params, this.options);
  }
}
