import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Loading } from '@stecpoint/ui-kit';
import FlagProvider from '@unleash/proxy-client-react';
import Invite from 'components/Auth/pages/invite/invite';
import { featureFlagConfig } from 'configs/featureFlagsConfig';
import { oidcConfig } from 'configs/oidcConfig';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { AuthProvider } from 'oidc-react';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import WebFont from 'webfontloader';
import { PrivateRoute } from './components/Auth/components/PrivateRoute/PrivateRoute';
import './configs/i18n';
import { INVITE_PAGE, LOGIN_PAGE, getRoute } from './constants/pagesNames';
import './index.css';
import { theme } from './indexConstants';
import { CurrentTheme } from './services/themingService/themes';

WebFont.load({
  google: {
    families: ['Roboto:100,200,300,400,500,700', 'Montserrat:300,400,500,700,900'],
  },
});

// TODO разобраться с прототипами, для чего были сделаны и нужны ли еще
// eslint-disable-next-line no-extend-native
Array.prototype.last = function() {
  return this ? this[this.length - 1] : undefined;
};

// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function f() {
  return `${format(this, 'yyyy-MM-dd')}T00:00:00`;
};

// eslint-disable-next-line no-extend-native
Map.prototype.toJSON = function toJSON() {
  // @ts-ignore
  return [...Map.prototype.entries.call(this)];
};

const App = React.lazy(() => import('./components/App/App'));
const Login = React.lazy(() => import('./components/Auth/pages/Login/Login'));

export const isMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
  navigator.userAgent
);

ReactDOM.render(
  <ThemeProvider theme={CurrentTheme(theme)}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      <AuthProvider {...oidcConfig}>
        <Suspense fallback={<Loading />}>
          <Router>
            <Switch>
              <Route path={getRoute(LOGIN_PAGE)}>
                <Login />
              </Route>
              <Route path={getRoute([INVITE_PAGE, ':token'])}>
                <Invite />
              </Route>
              <PrivateRoute path="/">
                <FlagProvider config={featureFlagConfig}>
                  <App />
                </FlagProvider>
              </PrivateRoute>
              <Redirect to={getRoute(LOGIN_PAGE)} />
            </Switch>
          </Router>
        </Suspense>
      </AuthProvider>
      <CssBaseline />
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
