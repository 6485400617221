import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '14px',
      border: '0.5px solid rgba(255,255,255,0.75)',
      fontWeight: 300,
      color: '#FFF',
      height: '40px',
      '&:hover': {
        border: '0.5px solid #FFF',
      },
      '&$focused': {
        border: '0.5px solid #FFF',
      },
      '&:[value="*"]': {
        color: 'red',
      },
    },
    focused: {},
  })
);

export const AuthInput: FC<InputBaseProps> = (props) => {
  const classes = useStyles();
  return <InputBase {...props} classes={classes} />;
};
