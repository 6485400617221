export const FLAT_MESSAGES = {
  ACCEPT_AGREEMENT_CONDITIONS: 'Примите условия соглашения',
  ACCEPT_AN_OFFER: 'Принять предложение?',
  ACCEPT_OFFER: 'Принять предложение',
  ACCEPT_OFFER_MODAL_TEXT: 'Заказ будет переведён в статус "Исполнение"',
  ACCOUNTED_IN_PACKING_SLIPS: 'Учтено в ОВ',
  ACTIVELY: 'Активно',
  ADD: 'Добавить',
  ADDED: 'Добавлено:',
  LOADING_PLAN: 'Погрузка, план',
  ADDING_CONTRACT: 'Добавление договора',
  ADDING_NEED: 'Добавление потребности',
  ADDING_NOMENCLATURE_INTO_NEED: 'Добавление номенклатуры к потребности',
  ADDING_ROLE: 'Добавление роли',
  ADDING_SERVICE: 'Добавление услуги',
  ADDITIONAL_AGREEMENTS: 'Доп. соглашения',
  READY_FOR_SHIPMENT: 'Готовность к отгрузке',
  LOADING_FACT: 'Погрузка, факт',
  ADDITIONAL_AGREEMENT_CREATION_IS_LOCKED:
    'Невозможно создать доп. соглашение, т.к. для спецификации создано неподписанное доп. соглашение',
  ADDITIONAL_AGREEMENT_NUMBER: 'Номер Доп. соглашения',
  ADDITIONAL_INFORMATION: 'Доп. информация',
  ADDITIONAL_PERFORMERS_CRITERIA: 'Дополнительные критерии подбора исполнителей',
  ADDITIONAL_PHONE: 'Доп. телефон',
  ADDITIONAL_REQUIREMENTS: 'Доп. требования',
  ADDITIONAL_REQUIREMENTS_COLUMN: 'Дополнительные требования',
  ADDITIONAL_SERVICE: 'Доп. услуга',
  ADDITIONAL_SERVICES: 'Доп. Услуги',
  ADDITIONAL_SERVICES_FULL: 'Дополнительные Услуги',
  ADDITIONAL_SERVICES_PRICE_NO_VAT: 'Стоимость доп. услуг без НДС',
  ADDRESS: 'Адрес',
  ADDRESSES_AND_ROUTES_CHANGED: 'В связи с изменением списка адресов были внесены изменения в маршруты.',
  ADDRESSES_NOT_INCLUDED_IN_ROUTES_FOUND: 'Обнаружены адреса, не включенные в маршруты.',
  ADDRESS_ADDING: 'Добавление адреса',
  ADDRESS_CELL: 'Адрес ячейки',
  ADDRESS_CREATION: 'Создание адреса',
  ADDRESS_NAME: 'Наименование адреса',
  ADDRESS_START_DATE: 'Дата начала действия адреса',
  ADDRESS_START_DATE_SHOULD_BE_MORE_THAN_PREVIOUS: 'Дата начала действия должна быть больше даты предыдущего адреса',
  ADDRESS_TYPE: 'Тип адреса',
  ADDRESS_VIEW_AND_SELECTION: 'Просмотр и выбор адреса',
  ADD_ADDRESS: 'Добавить адрес',
  DOCUMEN_LINK_MESSAGE: 'Перейти к документу',
  ADD_ALL: 'Добавить всех',
  ADD_ALL_FOUNDED: 'Добавить все найденные',
  ADD_ALL_REGIONS: 'Добавить все регионы',
  ADD_CARGOPLACES: 'Добавить грузоместа',
  ADD_CARGO_PLACE: 'Добавление грузоместа',
  ADD_CONTACT: 'Добавить контакт',
  ADD_DOCUMENT: 'Добавить документ',
  ADD_INTERMEDIATE_POINT: 'Добавить промежуточный пункт',
  ADD_NEW_COST_OFFER: 'Предложить новую стоимость перевозки',
  ADD_NEW_COST_OFFER_QUESTION: 'Предложить новую стоимость перевозки?',
  ADD_OFFER: 'Добавить предложение',
  ADD_ORGANIZATION: 'Добавить организацию',
  ADD_PERFORMER: 'Добавить исполнителя',
  ADD_PERFORMERS_ERROR: 'Ошибка при добавлении исполнителей',
  ADD_PERFORMERS_MODAL_TITLE: 'Добавление исполнителей',
  ADD_REGION: 'Добавить регион',
  ADD_ROLLING_STOCK: 'Добавление ПС и тарифов',
  ADD_ROLLING_STOCK_AND_TARIFFS: 'Добавить ПС и тарифы',
  ADD_ROLLING_STOCK_TITLE: 'Добавление подвижного состава',
  ADD_SELECTED: 'Добавить выбранных',
  ADD_CHOSEN: 'Добавить выбранные',
  ADD_SERVICE: 'Добавить услугу',
  ADD_TO_CART: 'Добавить в корзину',
  ADD_TRACK_LABEL_TOOLTIP: 'Чтобы добавить трек-метку, пометьте текущую трек-метку на удаление',
  ADD_USER: 'Добавить пользователя',
  ADD_VECHICLE_AND_COST: 'Добавить ТС и стоимость рейса',
  ADD_VEHICLE: 'Добавление ТС',
  ADD_VEHICLE_TOOLTIP: 'Заполните блок с общими данными предложения по маршруту (дата начала работ…',
  ADD_VEHICLE_TRIP_VALIDATE: 'Должно быть не меньше, чем "Количество ТС"',
  ADJUSTED_GROSS_WEIGHT: 'Масса брутто скорректированная, кг',
  ADJUSTED_HEIGHT: 'Высота скорректированная, мм',
  ADJUSTED_LENGTH: 'Длина скорректированная, мм',
  ADJUSTED_NET_WEIGHT: 'Масса нетто скорректированная, кг',
  ADJUSTED_VOLUME: 'Объем скорректированный, м3',
  ADJUSTED_WIDTH: 'Ширина скорректированная, мм',
  ADMIN_PANEL: 'Админ панель',
  ADMIN_PANEL_V2: 'Панель администратора',
  ADVANCE: 'Аванс',
  ADVANCE_PERCENT: 'Аванс, %',
  ADDING_CATEGORIES: 'Добавление категорий',
  AGENCY: 'Агентский',
  AGENT: 'Агент',
  AGREED_GROSS_WEIGHT: 'Масса брутто согласованная, кг',
  AGREED_HEIGHT: 'Высота согласованная, мм',
  AGREED_LENGTH: 'Длина согласованная, мм',
  AGREED_NET_WEIGHT: 'Масса нетто согласованная, кг',
  AGREED_VOLUME: 'Объем согласованный, м3',
  AGREED_WIDTH: 'Ширина согласованная, мм',
  AGREEMENT_ACCEPTANCE: 'Я прочитал и принимаю условия соглашения',
  ALLOCATE_AVAILABLE_QUANTITY: 'Распределить доступное количество',
  ALL_INFO: 'Информировать исполнителей об указанной дате начала работ, но получать все предложения',
  ALL_REGIONS: 'Все регионы РФ',
  ALL_REGISTERED_PERFORMERS: 'Все зарегистрированные исполнители',
  ALL_WIDTH_INFO_LABEL: 'Информировать исполнителей об установленной НМЦД, но получать все предложения',
  ALL_WITHOUT_INFO_LABEL:
    'Не информировать исполнителей об установленной НМЦД (получать все предложения, значение указано только для внутреннего анализа итогов торгов)',
  AMOUNT: 'Количество',
  ANALOGUE: 'Аналог',
  ANALYTICAL_REFERENCE: 'Аналитическая справка',
  APARTMENT_NUMBER: 'Кв/офис',
  API_KEY: 'API ключ',
  APPLICATION_NOT_CREATED: 'Заявка не создана',
  APPLY: 'Применить',
  APPLYING_DISCOUNT: 'Применение скидки',
  APPLY_DISCOUNT: 'Применить скидку',
  APPROVAL_STATUS: 'Статус согласования',
  APPROVAL_TITLE: 'Вынесение на согласование',
  SUBMIT_FOR_CONSIDERATION: 'Вынести на рассмотрение',
  APPROVAL_TYPE: 'Тип согласования',
  APPROVE: 'Согласовать',
  APPROVER_FULL_NAME: 'Ф.И.О. утверждающего',
  APPROVE_SCHEDULE: 'Утвердить график',
  ARCHIVE: 'Архив',
  AREA_ADDRESS: 'Адрес площадки',
  AREA_CODE: 'Код площадки',
  AREA_CODE_EXAMPLE: 'Пример кода площадки: П01',
  AREA_M2: 'Площадь, м2',
  AREA_TYPE: 'Тип площадки',
  ARTICLE_CONTRACTOR: 'Артикул поставщика',
  ARTICLE_PRODUCER: 'Артикул производителя',
  ATTACH_ANOTHER: 'Прикрепить другой',
  ATTACH_DOCUMENT: 'Прикрепить документ',
  ATTENTION: 'Внимание',
  ATTENTION_APPROVAL_TEXT:
    'Для отправки документа на согласование необходимо выбрать минимум минимум одного согласующего',
  AUCTION_INFO_TEXT:
    'Автоматическое формирование справки по наибольшей итоговой стоимости предложения участника торгов',
  AUCTION_PERIOD: 'Период проведения аукциона (рассчитывается автоматически, в часах)',
  AUCTION_TOOLTIP:
    '"Аукцион (на повышение ставок)" является одним из типов сбора предложений, когда продавец устанавливает наименьшую стоимость контракта, а исполнитель увеличивает цену кратно заданному шагу, до тех пор пока торги не будут завершены или не будет достигнуто пороговое значение.',
  AUCTION_TYPE: 'Аукцион (на повышение ставок)',
  AUTHOR: 'Автор',
  AUTOCOMPLETION_OF_TRADES_ENABLED: 'Включено автозавершение торгов',
  AUTOCOMPLITE_TRADE: 'Автозавершение торгов',
  AUTOFORMING: 'Автоформирование справки',
  AUTO_TRANSPORTATION_COST: 'Стоимость авто перевозки, без НДС',
  AVAILABILITY_LOGISTICS_CONTRACT: 'наличие договора вида "Логистика"',
  AVAILABLE: 'Доступно',
  APPLICATIONS: 'Заявки',
  BACK: 'Назад',
  BALANCE: 'Остаток',
  BARGAIN: 'Торги',
  BARGAINING: 'Уторговывание',
  BARGAIN_BIDS_SELECTION: 'Выбор заявок для торгов',
  BARGAIN_CANCEL: 'Отменить торги',
  BARGAIN_CANCEL_QUESTION: 'Отменить торги?',
  BARGAIN_CARD_LABEL: 'Карточка Торгов',
  BARGAIN_CARGO_PACKS_LIST_REPORT: 'Список грузомест торгов',
  BARGAIN_NUMBER: 'Торги №',
  BARGAIN_PARAMETERS: 'Параметры торгов',
  BARGAIN_ROUTE_CARGO_PACKS_LIST_REPORT: 'Список грузомест маршрута торгов',
  BARGAIN_SPEC_PRICE: 'Стоимость по спецификации',
  BEFORE_START_WORK_INFO: 'Получать предложения только с датой начала работ равной или ранее указанной даты',
  BELTS_COUNT: 'Количество ремней',
  BEST_OFFER: 'Наилучшее предложение',
  BEST_OFFER_LOWER_CASE: 'наилучшее предложение',
  BLOCK: 'Корпус',
  BLOCKED: 'Заблокирован',
  BLOCKED_CHANGED_DATE: 'Дата изменения статуса блокировки',
  BLOCKING_REASON: 'Причина блокировки',
  BODY_STRUCTURE_TYPE: 'Конструкция кузова',
  BODY_TYPE: 'Тип кузова',
  BUILDING: 'Строение',
  CANCEL: 'Отменить',
  CANNOT_BE_EARLIER_THAN_THE_DATE_OF_READINESS_FOR_SHIPMENT: 'Не может быть раньше даты готовности к отгрузке',
  SHIPMENT_READY_DATES: 'Плановая дата погрузки не может быть раньше даты готовности к отгрузке',
  CANNOT_BE_EARLIER_THAN_LOADING_DATE: 'Не может быть раньше даты погрузки',
  CANCELED_ALL: 'Отменены',
  CANCEL_CHANGE: 'Отменить сохранение?',
  CANCEL_CHANGE_TEXT:
    'При изменении типа подачи предложений, текущие настройки условий торгов будут утеряны. Продолжить?',
  CANCEL_CREATION: 'Отменить создание?',
  CANCEL_REASON: 'Причина отмены',
  CANCEL_REFERENCE: 'Отменить справку',
  CANCEL_REFERENCE_CONFIRM: 'Отменить справку?',
  CANCEL_TASK_ERROR: 'Ошибка при отмене задачи',
  CANCEL_TASK_QN: 'Отменить задачу?',
  CAN_NOT_BE_ZERO: 'Не может быть нулевым',
  CARGO: 'Груз',
  CARGO_CODE: 'Код груза',
  CARGO_CONDITIONS: 'Условия перевозки',
  CARGO_GROUP: 'Группа грузов',
  CARGO_INSURANCE: 'Страхование груза',
  CARGO_KIND: 'Вид груза',
  CARGO_PLACES: 'Грузоместа',
  CARGO_PLACES_COUNT: 'Кол-во грузомест',
  CARGO_PLACE_NAME: 'Название грузоместа',
  CARGO_PLACE_POSITION: 'Позиция грузового места',
  CARGO_PRICE_VAT: 'Стоимость груза с НДС, руб',
  CARGO_SUMMARY_VOLUME: 'Общий объем: ',
  CARGO_SUMMARY_WEIGHT_GROSS: 'Общая масса брутто: ',
  CARGO_SUMMARY_WEIGHT_NET: 'Общая масса нетто: ',
  CARGO_TYPE: 'Тип груза',
  CART: 'Корзина',
  CART_IS_EMPTY: 'В корзине пока пусто',
  DELETE_DOCUMENT_QUESTION: 'Удалить документ?',
  DELETE_DOCUMENT_CONFIRM_QUESTION: 'Вы уверены, что хотите удалить выбранный документ из списка для загрузки?',
  CART_VALID_UNTIL: 'Срок действия корзины до',
  CATALOG: 'Каталог',
  CATALOG_SEARCH: 'Поиск по каталогу',
  CATEGORIES: 'Категории',
  CATEGORY: 'Категория',
  CATEGORY_CODE: 'Код категории',
  CELL: 'Ячейка',
  CELL_ADDRESS: 'Адрес ячейки',
  CERTIFICATE_STATUS: 'Статус справки о стоимости',
  CHANGED_SUM_VAT: 'Измененная сумма НДС',
  CHANGED_SUM_WITHOUT_VAT: 'Измененная сумма без НДС',
  CHANGED_SUM_WITH_VAT: 'Измененная сумма с НДС',
  CHANGED_UNIT_PRICE: 'Измененная цена за ед.',
  CHANGE_ADDITIONAL_AGREEMENT_STATUS_ERROR: 'Ошибка изменения статуса доп. соглашения',
  CHANGE_ADDITIONAL_SERVICE_PRICE: 'Изменить стоимость доп. услуг',
  CHANGE_PICTURE: 'Изменить картинку',
  CHANGE_SPECIFICATION_STATUS_ERROR: 'Ошибка изменения статуса спецификации',
  CHARACTERISTICS: 'Характеристики',
  CHOOSE: 'Выбрать',
  CHOOSE_CELLS: 'Выберите хотя бы одну ячейку',
  CHOOSE_MEMBERS: 'Выберите участников и расположите их в нужной последовательности для согласования.',
  CHOOSE_NEEDS_TO_EXCLUDE: 'Выберите хотя бы одну потребность для исключения',
  CHOOSE_NEEDS_TO_RECLAIM: 'Выберите хотя бы одну исключенную потребность для возврата',
  CHOOSE_SERVICE: 'Выберите услугу',
  CHOOSE_SUBMISSION_TYPE: 'Выберите тип подачи предложений',
  CITIZENSHIP: 'Гражданство РФ',
  CITY: 'Город',
  CLEAR: 'Очистить',
  CLEAR_CART: 'Очистить корзину',
  CLEAR_OFFER: 'Очистить предложение',
  CLIENT: 'Клиентская',
  CLIENT_ORDER: 'Клиентская заявка',
  CLOSE: 'Закрыть',
  CLOSE_QN: 'Закрыть?',
  CODE: 'Код',
  CODE_CARGO: 'Код грузоместа',
  CODE_ETSNG: 'Код ЕТСНГ',
  CODE_ETSNG_NAME: 'Название',
  CODE_LRV: 'Код ЛРВ',
  CODE_MTR: 'Код МТР',
  COLLECTING_APPLICATIONS_TOOLTIP:
    'Указать начальную максимальную цену договора можно справочно или с обязательным ограничением. Если НМЦД указана справочно, то исполнитель видит стоимость НМЦД, но может сформировать и подать предложение на любую сумму. Если НМЦД указана с обязательным ограничением, то исполнитель может подать предложение только ниже НМЦД. При этом полнота предложения (все условия заявки выполнены) не проверяется. Проверяется только итоговая сумма предложения без НДС. Если в поле "Начальная максимальная цена договора" не введено никаких значений, то системой будут фиксироваться все предложения исполнителей, без каких-либо ограничений.',
  COLLECTING_APPLICATIONS_TYPE: 'Сбор ставок',
  COLLECTING_APPLICATION_INFO_TEXT:
    'Автоматическое формирование справки с полным перечнем предложений участников торгов',
  COLLECTING_OFFERS: 'Сбор предложений',
  COLLECTION_WILL_AUTOMATICALLY_SUSPENDED:
    'Сбор предложений будет автоматически приостановлен в установленные дату / время.',
  COMMENT: 'Комментарий',
  COMMENT_FOR_OFFER: 'Комментарий по предложению',
  COMMON_ERRORS_MESSAGES: 'Не заполнены обязательные поля или имеются ошибки:',
  COMPLETED_DATA_WILL_BE_LOST: 'Заполненные данные будут утеряны.',
  COMPLITED_ALL: 'Завершены',
  COMPLITE_COLLECTION: 'Завершить сбор',
  CONDITIONS_FOR_SUBMITTING: 'Условия подачи предложений',
  CONFIGURATOR_HYPHEN: 'Конфи-гуратор',
  CONFIRM: 'Подтвердить',
  CONFIRM_MULTILNG: 'Подтвердить/Confirm',
  CONSIGNEE: 'Грузополучатель',
  CONSIGNEE_ADDRESS: 'Адрес грузополучателя',
  CONSIGNOR: 'Грузоотправитель',
  CONSIGNOR_ADDRESS: 'Адрес грузоотправителя',
  CONTACT_CREATING: 'Добавление контакта',
  CONTACT_EDITING: 'Редактирование контакта',
  CONTACT_INFO: 'Контактные данные',
  CONTACT_PERSON: 'Контактное лицо',
  CONTINUE_ALLOCATE_QUESTION:
    'После выполнения распределения всё доступное количество перейдет в черновик спецификации. Продолжить?',
  CONTINUE_CHECKOUT_UNAVAILABLE_ITEMS_WILL_BE_EXCLUDED: 'Продолжить оформление? (недоступные товары будут исключены)',
  CONTINUE_QUESTION: 'Продолжить?',
  CONTRACT: 'Договор',
  CONTRACTOR: 'Поставщик',
  CONTRACTORS_ORGANIZATION_APPROVED: 'статус организации исполнителя: "Допущен"',
  CONTRACTORS_TIN: 'ИНН поставщика',
  CONTRACTORS_KPP: 'КПП поставщика',
  CONTRACTS: 'Договоры',
  CONTRACT_FINISH_VALIDATE: 'Дата окончания действия договора не может быть меньше даты начала действия договора',
  CONTRACT_NUMBER: 'Номер договора',
  CONTRACT_NUMBER_SYMBOL: 'Договор №',
  CONTRACT_SIGNED: 'подписан',
  CONTRACT_START_VALIDATE: 'Дата начала действия договора не может быть больше даты окончания действия договора',
  CONTRACT_TYPE: 'Тип договора',
  CONTRACT_WITH_OPERATOR: 'Договор с оператором',
  CONTRAGENTS_HYPHEN: 'Контра-генты',
  CONVERSION_COEFFICIENT: 'Коэффициент пересчета',
  CONVICTED: 'Не судимый водитель',
  COPY: 'Копия',
  COPY_WITH_CARGO: 'Копировать с грузоместами',
  COST: 'Стоимость',
  COST_ADDITIONAL_NO_VAT: 'Доп. услуги, без НДС',
  COST_ALL_NO_VAT: 'Стоимость всего, без НДС',
  COST_ALL_VAT: 'Стоимость всего, c НДС',
  COST_AUTO_NO_VAT: 'Автоперевозка, без НДС',
  COST_NO_VAT: 'Стоимость без НДС',
  COST_OF_SERVICES: 'Стоимость услуг',
  COST_PRICE_NO_VAT: 'Стоимость по прайсу без НДС',
  COST_PRICE_VAT: 'Стоимость по прайсу с НДС',
  COST_RAIL_NO_VAT: 'ЖД перевозка, без НДС',
  COST_VAT: 'Стоимость с НДС',
  COUNTERPARTY: 'Контрагент',
  COUNTRY_OF_ORIGIN: 'Страна происхождения',
  CREATE: 'Создать',
  CREATED: 'Создана',
  CREATED_BY: 'Создал',
  CREATED_V2: 'Создано',
  CREATE_ADDITIONAL_AGREEMENT: 'Создать доп соглашение',
  CREATE_ADDITIONAL_AGREEMENT_ATTENTION:
    'После создания доп соглашения будет недоступна работа с отгрузочными ведомостями и графиками производства в рамках спецификации до подписания или отмены доп соглашения.',
  CREATE_ADDRESS: 'Создать адрес',
  CREATE_AREA: 'Создать площадку',
  CREATE_BARGAIN: 'Создать торги',
  CREATE_BARGAIN_MODAL_TITLE: 'Создание торгов',
  CREATE_CONTRACT: 'Создание договора',
  CREATE_CONTRACT_PURCHASE_TOOLTIP: 'Создается в модуле "Договоры". Недоступно из карточки закупки.',
  CREATE_DEPARTMENT: 'Создать подразделение',
  CREATE_FEATURE: 'Создать характеристику',
  CREATE_LETTER: 'Создание письма-заявки',
  CREATE_NEW: 'Создать новый',
  CREATE_NEW_F: 'Создать новую',
  CREATE_ORGANIZATION: 'Создать организацию',
  CREATE_PURCHASE: 'Создать закупку',
  CREATE_REFERENCE_DISABLED_TOOLTIP:
    'Для создания аналитической справки необходимо завершить редактирование предложений.',
  CREATE_REQUEST: 'Создать заявку',
  CREATE_ROUTE_POINT: 'Добавление пункта',
  CREATE_SECTOR: 'Создать сектор',
  CREATE_SECTOR_TYPE: 'Создать тип сектора',
  CREATE_SERVICE: 'Создать услугу',
  CREATE_SHELVING: 'Создать стеллаж',
  CREATE_USER: 'Создать пользователя',
  CREATING_ROUTE: 'Создание маршрута',
  CREATING_SERVICE: 'Создание услуги',
  CREATION_ADDITIONAL_AGREEMENT_ERROR: 'Ошибка при создании доп. соглашения',
  CREATION_APPLICATION: 'Создание заявки',
  CREATION_AREA: 'Создание площадки',
  CREATION_CELL: 'Создание ячейки',
  CREATION_DATE: 'Дата создания',
  CREATION_DEPARTMENT: 'Создание подразделения',
  CREATION_NEW_NOMENCLATURE: 'Создание новой номенклатуры',
  CREATION_ORGANIZATION: 'Создание организации',
  CREATION_PACKING_SLIP: 'Создание отгрузочной ведомости',
  CREATION_PACKING_SLIP_COPY: 'Создание копии отгрузочной ведомости',
  CREATION_PURCHASE: 'Создание закупки',
  CREATION_SECTOR: 'Создание сектора',
  CREATION_SHELVING: 'Создание стеллажа',
  CREATION_SPECIFICATION_ERROR: 'Ошибка при создании спецификации',
  CREATION_STORAGE_BASE: 'Создание базы хранения',
  CREATION_TASK: 'Создание задачи',
  CREATION_TASK_ERROR: 'Ошибка при создании задачи',
  CREATING_PREQUALIFICATION_CARD: 'Создание карточки предквалификации',
  CURRENCY: 'Валюта',
  CURRENT: 'Текущий',
  CURRENT_ROUTE: 'Текущий маршрут',
  CUSTOMER: 'Заказчик',
  PREDQUALIFICATION: 'Предквалификация',
  CUSTOMER_TIN: 'ИНН заказчика',
  CUSTOMER_KPP: 'КПП заказчика',
  CUSTOMER_ORGANIZATION: 'Организация заказчика перевозки',
  CYRILLIC_UPPERCASE_FIRST_AND_NUMBERS:
    'Допускаются буквы (кириллица, заглавные) и цифры. Первым символом должна быть буква.',
  DASH: '—',
  DATE: 'Дата',
  DATE_CHANGE_CONTRACT_STATUS: 'Дата изменения статуса договора',
  DATE_CONTRACT_CONCLUSION: 'Дата заключения договора',
  DATE_DISPATCH_FACT: 'Дата отгрузки факт',
  DATE_END: '\tСрок до',
  DATE_END_AUCTION: 'Дата и время завершения проведения аукциона',
  DATE_END_REDUCTION: 'Дата и время завершения проведения редукциона',
  DATE_OF_CONCLUSION: 'Дата заключения договора',
  DATE_OF_CONCLUSION_CHANGED: 'Дата изменения статуса договора',
  DATE_RECIPT_FACT: 'Дата доставки факт',
  DATE_START: 'Срок с',
  DATE_START_AUCTION: 'Дата и время начала проведения аукциона',
  DATE_START_REDUCTION: 'Дата и время начала проведения редукциона',
  DATE_TIME_END_AUCTION: 'Дата и время завершения проведения аукциона',
  DATE_TIME_END_REDUCTION: 'Дата и время завершения проведения редукциона',
  DECLARED_QUANTITY: 'Количество заявлено',
  DECLARED_VALUE: 'С объявленной ценностью',
  DEFAULT_ROUTE_START_WORK_DATE_TOOLTIP:
    'По умолчанию отображается наиболее ранняя дата из указанных по всем созданным маршрутам.',
  DEFERRED_PAYMENT_DAYS: 'Отсрочка платежа (дней)',
  DELETE: 'Удалить',
  DELETED: 'Удалена',
  DELETED_STATUS: 'Удален',
  DELETE_ADDITIONAL_AGREEMENT_ERROR: 'Ошибка при удалении доп. соглашения',
  DELETE_ADDITIONAL_AGREEMENT_MESSAGE: 'Выбранное доп. соглашение будет удалено.',
  DELETE_ADDITIONAL_AGREEMENT_QUESTION: 'Удалить доп. соглашение?',
  DELETE_CONTRACT: 'Удалить договор',
  DELETE_CONTRACT_QUESTION: 'Удалить договор?',
  DELETE_CONTRACT_SUBTITLE: 'Договор будет удален и станет недоступен для редактирования.',
  DELETE_DATE: 'Дата удаления',
  DELETE_ERROR: 'Произошла ошибка при удалении',
  DELETE_NOT_POSSIBLE: 'Удаление невозможно',
  DELETE_POINT: 'Удалить пункт',
  DELETE_POINT_CONTENT: 'Выбранный пункт будет удален из маршрута торгов.',
  DELETE_POINT_QUESTION: 'Удалить пункт маршрута?',
  DELETE_PRIMARY_LETTER_QUESTION: 'Удалить первичное письмо?',
  DELETE_REASON: 'Причина удаления',
  DELETE_ROLLING_STOCK: 'Удалить подвижной состав?',
  DELETE_ROLLING_STOCK_MESSAGE: 'Выбранный тип подвижного состава будет удален.',
  DELETE_ROLLING_STOCK_ROW_DESCRIPTION: 'ПС и тарифы будут удалены.',
  DELETE_ROLLING_STOCK_ROW_TITLE: 'Удалить ПС и тарифы?',
  DELETE_ROUTE: 'Удалить маршрут',
  DELETE_ROUTE_QUESTION: 'Удалить маршрут?',
  DELETE_SECONDARY_LETTER_QUESTION: 'Удалить письмо-уточнение?',
  DELETE_SERVICE_QUESTION: 'Удалить услугу?',
  DELETE_STORAGE_BASE: 'Удалить базу хранения?',
  DELETE_USER: 'Удалил',
  DELETE_VEHICLE_ROW_DESCRIPTION: 'ТС и стоимость рейса будут удалены.',
  DELETE_VEHICLE_ROW_TITLE: 'Удалить ТС и стоимость рейса?',
  DELETING_SPECIFICATION_ERROR: 'Ошибка удаления спецификации',
  DELIVERED_WITHOUT_VAT: 'Поставлено без НДС',
  DELIVERY: 'Доставка',
  DELIVERY_CURRENCY: 'Валюта стоимости доставки',
  DELIVERY_ADDRESS: 'Адрес доставки',
  DELIVERY_BASIS: 'Базис поставки',
  DELIVERY_BY_THE_SUPPLIER: 'Доставка силами поставщика',
  DELIVERY_COST: 'Стоимость доставки',
  DELIVERY_COST_EXCHANGE: 'Стоимость доставки, ₽',
  DELIVERY_COST_WITHOUT_VAT: 'Стоимость доставки без НДС',
  DELIVERY_DATE: 'Дата доставки',
  DELIVERY_DAYS_LIMIT: 'Срок поставки, дн.',
  DELIVERY_DAYS_LIMIT_FULL: 'Срок поставки, дней',
  DELIVERY_METHOD: 'Способ доставки',
  DELIVERY_OPTIONS: 'Условия поставки',
  DELIVERY_PAYMENT: 'Оплата доставки',
  DELIVERY_TIME_LIMIT: 'Срок поставки',
  DELIVERY_TYPE: 'Способ поставки',
  DELTA_SUM_WITHOUT_VAT: 'Дельта по сумме без НДС',
  DENOMINATION: 'Наименование',
  DEPARTMENT: 'Подразделение',
  DEPARTMENT_FULL_NAME: 'Полное название подразделения',
  DEPARTMENT_NAME: 'Отдел',
  DEPARTMENT_SHORT_NAME: 'Краткое название подразделения',
  DEPARTURE_STATION_RATES_NO_VAT: 'Расходы на станции отправления за ед. ПС, без НДС',
  DESCRIPTION: 'Описание',
  DESIRED_DELIVERY_METHOD: 'Желаемый способ доставки',
  DEVIATION_FROM_BEST_OFFER: 'отклонение от наилучшего предложения:',
  DIRECTORIES: 'Справочники',
  DISCOUNT: 'Скидка',
  DISPATCH: 'Отгрузка',
  DISTRICT: 'Район',
  DOCUMENT: 'Документ',
  DOCUMENTS: 'Документы',
  DOCUMENTS_HYPHEN: 'Доку- менты',
  DOCUMENT_APPROVAL_STATUS: 'Статус согласования документа',
  DOCUMENT_MANAGEMENT: 'Документооборот',
  DOCUMENT_MANAGEMENT_EMAIL: 'Эл.почта для документооборота',
  DOCUMENT_NUMBER: 'Номер документа',
  DOCUMENT_TYPE: 'Тип документа',
  DOWNLOAD_CARGO_PACK_LIST: 'Скачать список грузомест',
  DOWNLOAD_DOCUMENTS: 'Загрузить документы',
  DOWNLOAD_IMPORT_CATEGORIES_TEMPLATE: 'Скачать шаблон импорта категорий',
  EXPORT_CATEGORIES: 'Экспорт категорий',
  DOWNLOAD_IMPORT_GOODS_TEMPLATE: 'Скачать шаблон импорта товаров',
  DOWNLOAD_IMPORT_PRICE_TEMPLATE: 'Скачать шаблон импорта цен',
  DOWNLOAD_IN_EXCEL: 'Скачать в Excel',
  DOWNLOAD_TEMPLATE: 'Скачать шаблон',
  DOWNLOAD_TEMPLATE_FAILED: 'Не удалось скачать шаблон, попробуйте позже.',
  DRIVER_ORIGINIAL: 'Оригинал доверенности',
  DRIVER_REQUIREMENTS: 'К водителю',
  DUE_DATE: 'Срок исполнения',
  DUMP_TYPES: 'Вариант выгрузки самосвала',
  DUMP_TYPES_COMMON: 'Вариант погрузки/выгрузки',
  ECOSYSTEM_MANAGEMENT: 'Управление экосистемой',
  ECOSYSTEM_ORGANIZATIONS: 'Организации экосистемы',
  EDIT: 'Редактировать',
  EDITING_NOT_AVAILABLE: 'Редактирование недоступно',
  EDITING_OFFER: 'Редактирование предложения',
  EDITING_ROUTE: 'Редактирование маршрута',
  EDIT_AND_WITHDRAW_MESSAGE:
    'Чтобы внести изменения, система отзовёт ваше предложение. После внесения изменений вы сможете отправить предложение повторно.',
  EDIT_AND_WITHDRAW_TITLE: 'Редактировать и отозвать предложение?',
  EDIT_CONTRACT: 'Редактирование договора',
  EDIT_DATA: 'Редактировать данные',
  EDIT_END_COLLECTION_OFFERS_DATE: 'Редактировать окончание сбора предложений',
  EDIT_LIST: 'Редактировать список',
  EDIT_ORGANIZATION_ADDRESS_ERROR: 'Ошибка при редактировании адреса',
  EDIT_POINT_ERROR_DATE_AFTER: 'Не может быть позднее исполнения в следующем пункте',
  EDIT_POINT_ERROR_DATE_BEFORE: 'Не может быть раньше исполнения в предыдущем пункте',
  EDIT_POINT_ERROR_PAST_TIME: 'Ввод прошедшего времени запрещен',
  EDIT_POINT_QUESTION: 'Редактировать пункт?',
  EDIT_REGION: 'Выбор региона перевозок',
  EDIT_ROLLING_STOCK: 'Редактирование ПС и тарифов',
  EDIT_ROUTE: 'Редактировать маршрут',
  EDIT_ROUTE_POINT: 'Редактирование пункта',
  EDIT_THE_FOLLOWING_ROUTES: 'Откорректируйте следующие маршруты:',
  EDIT_VEHICLE: 'Редактирование ТС',
  EDITED: 'Отредактировано',
  EDITED_BY: 'Отредактировал',
  EMAIL: 'Эл. почта',
  EMERGENCY_OR_INDEFINITE: 'Срочный/Бессрочный',
  ENABLED: 'Включено',
  END_COLLECTION_OF_BIDS: 'Окончание сбора предложений по торгам ',
  END_DATE_FACT: 'Дата окончания факт',
  END_DATE_PLAN: 'Дата окончания, план',
  END_EDIT_OFFERS: 'Завершить редактирование предложений',
  END_EDIT_OFFERS_QUESTION: 'Завершить редактирование предложений?',
  END_EDIT_OFFERS_TEXT:
    'Исполнители больше не смогут внести изменения в свои предложения в соответствии с новой стоимостью перевозки.',
  END_POINT: 'Конечный пункт',
  END_PROPOSAL_COLLECTION: 'Окончание сбора предложений',
  ENTER_CODE_MULTILNG: 'Введите код/Enter the code',
  ENTER_MULTILNG: 'Вход/Enter',
  ENTER_NEW_PASSWORD_MULTILNG: 'Введите новый пароль/Enter new password',
  ENTER_NOT_ZERO_VALUE: 'Введите значение отличное от нуля',
  ENTER_PACKING_SLIP_NAME: 'Введите наименование отгрузочной ведомости',
  ENTER_PASSWORD_MULTILNG: 'Ввести пароль/Enter password',
  ENTER_SPECIFICATION_NUMBER: 'Введите номер спецификации',
  ENTER_STATUS: 'Введите статус',
  ENTER_CATEGORY_SUBCATEGORY_OR_CATEGORY_CODE: 'Введите категорию, подкатегорию или код категории',
  ERROR_SUBMITTING_OFFER: 'Возникла ошибка при отправке предложения',
  ERROR_UPLOAD_FILE: 'Произошла ошибка при загрузке файла.',
  ESCORT: 'Сопровождение',
  ESTIMATED_COST: 'Стоимость сметная',
  ESTIMATED_DELIVERY_TIME: 'Расчетная дата поставки',
  ESTIMATED_PRICE_PER_UNIT_WITHOUT_VAT: 'Цена сметная за ед. без НДС',
  EXCEL_FILE_EXTENSION: 'xlsx',
  EXCESS_IDLE_TIME: 'Ставка за сверхнормативный простой',
  EXCLUDE: 'Исключить',
  EXCLUDE_CARGOPLACE: 'Исключить грузоместо?',
  EXCLUDE_CARGOPLACE_MESSAGE: 'Выбранные грузоместа будут исключены из состава торгов.',
  EXCLUDE_FROM_PACKING_SLIP: 'Исключить из ОВ',
  EXCLUDE_FROM_PACKING_SLIP_TITLE: 'Исключение грузоместа из отгрузочной ведомости',
  EXCLUDE_PERFORMER: 'Исключить исполнителя?',
  EXCLUDE_PERFORMERS_MESSAGE: 'Выбранные исполнители будут исключены из списка потенциальных участников.',
  EXCLUDE_REASON: 'Причина исключения',
  EXCLUDE_UNAVAILABLE_PRODUCTS: 'Исключить недоступные товары',
  EXECUTION: 'Исполнение:',
  EXECUTION_FACT_DATE: 'Исполнение, факт:',
  EXECUTION_LABEL: 'Исполнение',
  EXECUTION_PLAN_DATE: 'Исполнение, план:',
  EXECUTION_POINT: 'Пункт выполнения',
  EXECUTION_VARIANT: 'Вариант исполнения:',
  EXECUTION_VARIANT_LABEL: 'Вариант исполнения',
  EXISTENCE_AGREEMENT_DISAGREEMENTS: 'Наличие соглашения о принятии разногласий',
  EXPAND_ALL_ROWS: 'Развернуть все строки',
  EXPORT_ERROR: 'Ошибка экспорта',
  EXPORT_TO_EXCEL: 'Экспортировать в Excel',
  ENTER_SUPPLIER_NAME_ORGANIZATION_OR_TIN_KPP: 'Введите название организации поставщика или ИНН/КПП',
  FEATURE: 'Характеристика',
  FILE: 'Файл',
  FILE_CANNOT_BE_UPLOADED: 'Невозможно загрузить файл.',
  FILE_NOT_PASS_ANTIVIRUS: 'Файл не прошел антивирусную проверку.',
  FILLING_CONDITIONS: 'Заполнение условий',
  FILL_ROUTE_INFO: 'Заполните информацию по маршрутам',
  FINANCIAL_SERVICES: 'Финансовые услуги',
  FINFACTORY_CONDITIONS: 'Выгодные условия от официального партнёра FINFACTORY',
  FINISH_ACTION: 'Окончание действия',
  FIRST_NAME: 'Имя',
  FIRST_USER_DATE_REGISTER: 'Дата регистрации первого пользователя на платформе',
  FIXED_RATE: 'Фиксированная ставка (RUB, без НДС)',
  FIXED_RATE_INFO_TEXT: 'Автоматическое формирование справки по первому предложению = фиксированной ставке',
  FIXED_RATE_TOOLTIP:
    'Исполнители могут подать предложение только по указанной фиксированной ставке. Указать дату начала работ можно справочно или с обязательным ограничением. Если дата указана справочно, то исполнитель видит заданную дату, но может указать любую дату начала исполнения при формировании и подаче предложения. Если дата указана с обязательным ограничением, то исполнитель может указать дату начала исполнения только равной или ранее заданной даты.',
  FIXED_RATE_TYPE: 'Фиксированная ставка',
  FORGOT_PASSWORD_MULTILNG: 'Забыли пароль?/Forgot your password?',
  FORM_VALIDATION_MAX_LENGTH: 'Количество знаков в поле должно быть не более',
  FORWARDING_FEE: 'Экспедиторское вознаграждение за ед. ПС, без НДС',
  REFUSE_CONSIDERATION: 'Отказаться от рассмотрения',
  REFUSE_CONSIDERATION_TEXT:
    'Вы уверены, что хотите отказаться от рассмотрения документа? Вернуть документ на рассмотрение будет нельзя.',
  FRAMEWORK_TYPE: 'Рамочный',
  FROM: 'из',
  FULL_NAME: 'Полное наименование',
  FULL_NAME_PERFORMER: 'ФИО исполнителя',
  FULL_OFFER: 'Полное предложение',
  GENERAL_LIST: 'Общий список',
  GENERAL_OFFER: 'Общее предложение',
  GENERAL_REQUIREMENTS: 'Общие требования',
  GOODS: 'Товары',
  GO_TO_CART: 'Перейти в корзину',
  GO_TO_CATALOG: 'Перейти в каталог',
  GO_TO_CONTRACT: 'Перейти к договору',
  GO_TO_EXPORT_PAGE: 'Перейти в журнал экспорта',
  GO_TO_HELP_DESK: 'Перейти на HelpDesk',
  GO_TO_ORDERS: 'Перейти в Заказы',
  GO_TO_SPECIFICATION: 'Перейти в спецификацию',
  GROUP: 'Группа',
  GROUP_CREATION: 'Создание группы',
  GROUP_EDITING: 'Редактирование группы',
  HARD_BOARD: 'Жесткий борт',
  HAS_ACCREDITED_RESOURCES: 'Наличие аккредитованных ресурсов',
  HAS_ACCREDITED_RESOURCES_TOOLTIP:
    'Если отмечено чекбоксом, то системой будут предложены только те исполнители, у которых есть хотя бы один ресурс в статусе "Допущен". Иначе - все исполнители.',
  HAS_CONTRACT: 'Наличие договора',
  HAS_POSITIVE_EXPERIENCE: 'Наличие положительного опыта работы на платформе',
  HAS_POSITIVE_EXPERIENCE_TOOLTIP:
    'Если отмечено чекбоксом, то системой будут предложены только те исполнители, которые победили в торгах хотя бы один раз. Иначе - все исполнители.',
  HAZARD_CLASS: 'Класс опасности',
  HEIGHT_M: 'Высота, м',
  HEIGHT_MM: 'Высота, мм',
  HEIGHT_ONE_PLACE: 'Высота одного места, см',
  HELP_DESK: 'Тех. поддержка',
  HOUR: 'ч.',
  HOUSE: 'Дом',
  IMAGE: 'Изображение',
  IMMEDIATE: 'Срочный',
  IMPORT_CATEGORIES: 'Импорт категорий',
  IMPORT_FROM_EXCEL: 'Импорт из Excel',
  IMPORT_GOODS_TEMPLATE: 'Шаблон импорта товаров',
  IMPORT_PRICES: 'Импортировать цены',
  IMPORT_PRICE_TEMPLATE: 'Шаблон импорта цен',
  INCLUDE_IN_REFERENCE: 'Включить в справку',
  INCLUDE_IN_ROUTE: 'Включить в маршрут',
  INCORRECT_CODE_MULTILNG: 'Неверный смс-код/ Incorrect code',
  INCORRET_LOGIN_OR_PASSWORD_MULTILNG: 'Неверный логин или пароль/ Incorrect Login or Password',
  INFORMATION: 'Информация',
  INN: 'ИНН',
  INPUT_NUMBER: 'Введите номер',
  INSURANCE_RATE: 'Ставка страхования, %',
  INVALID_ROLE_COMBINATION:
    'Недопустимая комбинация ролей: роли, относящиеся к типу организации “Поставщик” нельзя комбинировать с ролями, относящимися к типу организации “Логистический оператор”',
  INVITE: 'Пригласить',
  INVITED_PERFORMERS: 'Приглашено исполнителей:',
  INVITE_ALL: 'Пригласить всех',
  INVITE_PERFORMERS_SUCCESS_MESSAGE: 'Исполнители добавлены для участия в торгах',
  INVITE_SELECTED: 'Пригласить выбранных',
  IN_ABSOLUTE_VALUE: 'В абсолютных значениях',
  IN_BARGAIN: 'Включено в торги',
  IN_PERCENT: 'В процентах',
  IN_SCHEDULES_OR_SPECIFICATION: 'В графиках / в спецификации',
  IN_SUBREQUEST: 'Включено в СЗ',
  IS_COMPLEX_PRODUCT: 'Является сложным изделием',
  IS_PROVIDE: 'Оказываю',
  IS_START_DATE_CONDITION: 'Выполнено условие даты начала работ',
  ITEM_AVAILABLE: 'В наличии',
  ITEM_NOT_AVAILABLE: 'Нет в наличии',
  I_ACCEPT_TERMS_EN: 'I accept the terms of the agreement',
  I_ACCEPT_TERMS_RU: 'Я принимаю условия соглашения/',
  JOB_TITLE: 'Должность',
  KIND: 'Вид',
  KPP: 'КПП',
  LARGEST_SPACE_COLUMN: 'Габариты самого объемного места',
  LAST_AUTO_DATE: 'Дата подачи последнего ТС',
  LAST_NAME: 'Фамилия',
  LAST_PAYMENT_DATE: 'Дата крайней оплаты',
  LAST_RAIL_DATE: 'Дата подачи последней ЖД ед.',
  LAST_TRAIN_SUBMISSION_DATE: 'Дата подачи последней жд. ед.',
  LAST_VEHICLE_SUBMISSION_DATE: 'Дата подачи последнего ТС',
  LATITUDE: 'Широта',
  LENGTH_M: 'Длина, м',
  LENGTH_MM: 'Длина, мм',
  LENGTH_ONE_PLACE: 'Длина одного места, см',
  LESS_NMCD_VALIDATOR_TEXT: 'Начальная максимальная цена договора должна быть меньше порогового значения',
  LESS_TRESHOLD_VALIDATOR_TEXT: 'Начальная минимальная цена договора должна быть больше порогового значения',
  LETTER: 'Литера',
  LETTERS_WILL_BE_DELETED: 'Все уточняющие письма будут удалены.',
  LETTER_DATE: 'Дата письма',
  LETTER_NUMBER: 'Номер письма',
  LETTER_WILL_BE_DELETED: 'Выбранное письмо будет удалено.',
  LIMIT_FROM_NO_VAT: 'Лимит от, руб. без НДС',
  LIMIT_TILL_NO_VAT: 'Лимит до, руб. без НДС',
  LIST: 'Список',
  LIST_OF_CONTRACTS: 'Список договоров',
  LIST_PURCHASE: 'Прейскурантная закупка',
  LOADING_RATE: 'Норма загрузки',
  LOADING_RATE_TONN: 'Норма загрузки, т',
  LOADED: 'Загружен',
  LOCALITY: 'Населенный пункт',
  LOGIN_MULTILNG: 'Логин/Login',
  LOGISTIC: 'Логистика',
  LOGISTICS_MANAGEMENT: 'Управление логистикой',
  LOGISTICS_OPERATOR: 'Логистический оператор',
  LOGISTICS_OPERATOR_ORGANIZATION: 'Организация логистического оператора',
  LONGITUDE: 'Долгота',
  MAIN: 'Основное',
  MAIN_COLUMN: 'Общие данные',
  MARK: 'Маркировка',
  MARKET: 'Маркет',
  MARKET_STOCK: 'Биржа',
  MARK_ORGANIZATION_FOR_DELETION_QUESTION: 'Пометить организацию на удаление?',
  MASTER_CATALOGS: 'Мастер-каталоги',
  MASTER_POSITION: 'Мастер-позиция',
  MASTER_POSITIONS: 'Мастер-позиции',
  MEDICAL_BOOK: 'Наличие медицинской книжки',
  MEMBERS: 'Участники:',
  MEMBERS_LIST: 'Список участников',
  MESSAGE_IN_CHAT: 'Сообщение в чате',
  MIDDLE_NAME: 'Отчество',
  MINIMUM_AUCTION_PERIOD: 'Минимальный период проведения аукциона = 30 минут',
  MINIMUM_ORDER: 'Мин. партия',
  MINIMUM_REDUCTION_PERIOD: 'Минимальный период проведения редукциона = 30 минут',
  MINIMUM_START_TIME_OFFSET: 'Минимум + 2 часа от текущего времени',
  MORE_21DAYS_VALIDATION_TEXT: 'Дата не должна быть больше текущей более чем на 21 день',
  MORE_DETAILS: 'Подробнее',
  MORE_THAN_ALLOWED_VALUE: 'Больше допустимого значения',
  MOVE_CARGOPLACE_QUANTITY: 'Перемещаемых грузомест:',
  MOVE_CARGOPLACE_TITLE: 'Перемещение грузомест',
  MOVE_TO_ANOTHER_TRIP: 'Переместить в другой маршрут',
  MOVE_TO_ROUTE: 'Переместить в маршрут',
  MSK_TIME_ZONE: '(МСК) Московское время',
  MULTIMODAL_REQUIREMENTS: 'Требования к мультимодальной перевозке',
  MULTIMODAL_TRANSPORTATION: 'Мультимодальная перевозка',
  MULTIPLICITY: 'Кратность',
  MY_OFFER: 'Моё предложение',
  NAME: 'Название',
  NEED: 'Потребность',
  NEEDS: 'Потребности',
  NEEDS_EXPORT: 'Экспорт потребностей',
  NEEDS_WILL_BE_RECLAIMED: 'Отмеченные потребности будут возвращены в доп. соглашение. Продолжить?',
  NEEDS_WITHOUT_OFFERS: 'Потребностей без предложений:',
  NEED_CODE: 'Код потребности',
  NEED_EXCLUSION_IS_NOT_POSSIBLE: 'Исключение потребности невозможно:',
  NEED_WAS_EXCLUDED_FROM_ADDITIONAL_AGREEMENT: 'Потребность исключена из доп. соглашения',
  NEW_PRICE_TRANSPORTATION: 'Новая стоимость перевозки',
  NEW_PRICE_TRANSPORTATION_TEXT:
    'Ваше предложение будет отправлено и зафиксировано системой как новое наилучшее предложение.',
  NEXT: 'Далее',
  NMCD: 'НМЦД',
  NMCD_AUCTION: 'Начальная максимальная цена договора (RUB, без НДС)',
  NMCD_REDUCTION: 'Начальная минимальная цена договора (RUB, без НДС)',
  NO: 'Нет',
  NOMENCLATURE: 'Номенклатура',
  NOMENCLATURES: 'Номенклатуры',
  NOMENCLATURE_EDITING: 'Редактирование номенклатуры',
  NOMENCLATURE_NAME: 'Наименование номенклатуры',
  NOMENCLATURE_TYPE: 'Тип номенклатуры',
  NOTIFICATION: 'Уведомление',
  NOT_AFTER_END_AUCTION_DATE: 'Не может быть позже даты завершения проведения аукциона',
  NOT_AFTER_END_REDUCTION_DATE: 'Не может быть позже даты завершения проведения редукциона',
  NOT_AFTER_START_WORK_DATE: 'Не может быть позже даты начала работ',
  NOT_APPLICABLE: 'Не применимо',
  NOT_AVAILABLE_FOR_ORDER: 'Не доступен к заказу',
  NOT_BEFORE_START_AUCTION_DATE: 'Не может быть раньше даты начала проведения аукциона',
  NOT_BEFORE_START_REDUCTION_DATE: 'Не может быть раньше даты начала проведения редукциона',
  NOT_DECLARED: 'Без уточнения',
  NOT_FOUND_PAGE_BODY: 'К сожалению, данной страницы не существует или ссылка не верна',
  NOT_FOUND_PAGE_TITLE: '404 Ошибка',
  NOT_INSTALLED: 'Не установлен',
  NOT_REQUIRED: 'Не требуется',
  NOT_SET: 'Не установлено',
  NOT_SIGNED: 'не подписан',
  NOT_SUBMITTED: 'Не подано',
  NO_CURRENT_DATE_VALIDATION_TEXT: 'Нельзя выбрать текущую дату',
  NO_DATA: '—',
  NO_DATA_MESSAGE: 'Нет данных для отображения',
  NO_LOGISTICS_OPERATOR: 'Не найден логистический оператор',
  NO_LOGISTICS_OPERATOR_MESSAGE:
    'Невозможно отправить ресурс на проверку, пока не подписан логистический договор хотя бы с одним логистичеческим оператором.',
  NO_PAST_DATE_VALIDATION_TEXT: 'Дата не может быть меньше текущей',
  NO_SMS_MULTILNG: "Не пришел код?/Didn't receive a code?",
  NO_TASKS_AVAILABLE: 'Нет доступных задач',
  NUMBER: 'Номер',
  NUMBER_OF_SELECTED_CATEGORIES: 'Кол-во выбранных категорий',
  NUMBER_OF_CONFIRMED_CATEGORIES: 'Кол-во подтвержденных категорий',
  OBJECT: 'Объект',
  OBJECT_FIELD_IS_REQUIRED: 'Поле "Объект" обязательно для заполнения',
  OFF: 'Выключено',
  DELIVERY_PLAN: 'Доставка, план',
  DELIVERY_FACT: 'Доставка, факт',
  CONTACT: 'Контакт',
  OFFER: 'Предложение',
  OFFERS_SUBMITTED: 'Подано предложений:',
  OFFER_ANALYSIS: 'Анализ предложений',
  OFFER_CANNOT_BE_SENT: 'Предложение не может быть отправлено:',
  OFFER_CHANGES_WILL_NOT_BE_SAVED_CONTINUE: 'Изменения по предложению не будут сохранены. Продолжить?',
  OFFER_COMMENT: 'Комментарий по предложению',
  OFFER_DETAILS: 'Детали предложения',
  OFFER_END_DATE: 'Дата окончания по предложению',
  OFFER_QUANTITY: 'Количество по предложению',
  OFFER_RAILS_APPROVED_COUNT: 'Количество ПС утверждено',
  OFFER_RAILS_COUNT: 'Количество ПС по предложению',
  OFFER_SENDED: 'Предложение отправлено',
  OFFER_SENDED_SUCCESSFULLY: 'Ваше предложение успешно отправлено.',
  OFFER_SENT: 'Предложение отправлено',
  OFFER_SENT_TO_CONSIDERATION: 'Ваше предложение успешно отправлено на рассмотрение.',
  OFFER_SENT_TO_CUSTOMER: 'Предложение отправлено заказчику.',
  OFFER_START_DATE: 'Дата начала по предложению',
  OFFER_STATUS: 'Статус предложения',
  OFFER_TRIPS_APPROVED_COUNT: 'Количество рейсов утверждено',
  OFFER_TRIPS_COUNT: 'Количество рейсов по предложению',
  OFFER_TYPE: 'Тип подачи предложений',
  OFFER_VEHICLES_APPROVED_COUNT: 'Количество ТС утверждено',
  OFFER_VEHICLES_COUNT: 'Количество ТС по предложению',
  OFFER_VEHICLES_SUBTOTAL_PRICE: 'Стоимость всего по типу ТС, без НДС',
  OFFER_WITHDRAWN: 'Предложение отозвано',
  OFFER_WITHDRAW_ERROR: 'Ошибка при отзыве предложения',
  OFF_SHORT: 'Выкл.',
  OK: 'Ок',
  ONE_TRIP_PRICE: 'Стоимость за 1 рейс без НДС ₽',
  ONE_TRIP_PRICE_WITHOUT_RUB: 'Стоимость за 1 рейс без НДС',
  ONLY_BELOW_NMCD_LABEL: 'Получать предложения только ниже установленной НМЦД',
  ONLY_PDF_FUNCTION: 'Функция доступна только для документов формата PDF',
  ON_EXACT_TIME: 'В установленные дату/время',
  ON_FIRST_FIXED_RATE_OFFER: 'По первому предложению по фиксированной ставке',
  ON_SHORT: 'Вкл.',
  ON_TRESHOLD: 'По пороговому значению',
  OPERATOR: 'Оператор',
  ORDER: 'Заказать',
  ORDERED: 'Заказано',
  ORDERED_SUCCESS: 'Ваш заказ успешно оформлен!',
  ORDERING: 'Оформление заказа',
  ORDERS: 'Заказы',
  ORDER_CANCEL_MODAL_CANCEL_CONFIRM: 'Отменить заказ?',
  ORDER_CANCEL_MODAL_CANCEL_ERROR: 'Прежде чем продолжить, необходимо указать причину',
  ORDER_CANCEL_MODAL_CANCEL_WARNING: 'Заказ перейдёт в статус "Отменен". Дальнейшие действия по нему будут невозможны.',
  ORDER_CODE: 'Код заказа',
  ORDER_COMMENT: 'Комментарий к заявке',
  ORDER_DETAILS_CAN_ALWAYS_BE_VIEWED_IN_THE_ORDERS_SECTION: 'Детали заказа всегда можно посмотреть в разделе «Заказы»',
  ORDER_ITEM_STATUS: 'Статус товара',
  ORDER_NUMBER: 'Номер заказа',
  ORDER_STATUS_CONFIRMED: 'Подтверждена',
  ORGANIZATION: 'Организация',
  ORGANIZATIONAL_AND_LEGAL_FORM: 'Организационно-правовая форма',
  ORGANIZATIONS: 'Организации',
  ORGANIZATION_CAN_HAVE_ONLY_ONE_LEGAL_ADDRESS: 'У организации может быть только один юридический адрес',
  ORGANIZATION_EXPERT: 'Организация-эксперт',
  ORGANIZATION_FULL_NAME: 'Полное наименование организации',
  ORGANIZATION_MANAGEMENT: 'Управление организациями',
  ORGANIZATION_PERFORMER: 'Организация-исполнитель',
  ORGANIZATION_PRINCIPAL: 'Организация-принципал',
  ORGANIZATION_WILL_BE_MARKED_FOR_DELETION: 'Организация будет помечена на удаление.',
  OUR_MANAGER_WILL_CONTACT_YOU: 'Наш менеджер свяжется с вами для уточнения деталей.',
  OWNERSHIP_TYPE: 'Тип владения',
  PACKAGE_TYPE: 'Вид упаковки',
  PACKING_LINK_PART: 'Наим.',
  PACKING_SLIP: 'Отгрузочная ведомость',
  PACKING_SLIPS: 'Отгрузочные ведомости',
  PACKING_SLIP_CARGOPLACES_REPORT: 'Список грузомест отгрузочной ведомости',
  PACKING_SLIP_IS_LOCKED:
    'Отгрузочная ведомость заблокирована до момента подписания или отмены доп соглашения в рамках родительской спецификации',
  PACKING_SLIP_NAME: 'Название отгрузочной ведомости',
  PACKING_SLIP_NUMBER: 'Номер отгрузочной ведомости',
  PACKING_SLIP_PROCESSING_IS_LOCKED:
    'Невозможна работа с ведомостью, т.к. для спецификации создано неподписанное доп. соглашение',
  PAID_SEPARATELY: 'Оплачивается отдельно',
  PARALLEL: 'Параллельное',
  PARTICIPANTS_LIST_FORMATION: 'Формирование списка участников',
  PASSPORTS: 'Паспорта',
  PASSWORD_MULTILNG: 'Пароль/Password',
  PAYMENT_STATUS: 'Статус оплаты',
  PAYMENT_TERMS: 'Условия оплаты',
  PERFORMER: 'Исполнитель',
  PERFORMERS: 'Исполнители',
  PERFORMERS_SUGGESTIONS: 'Предложения исполнителей',
  PERFORMER_INSURANCE: 'Страхование гражданской ответственности',
  PERFORMER_STATUS: 'Статус исполнителя',
  PERFORMER_SUGGESTION: 'Предложение исполнителя',
  PERSONAL_DATA_AGREEMENT: 'Согласие на обработку персональных данных',
  PHONE: 'Телефон',
  PICKUP: 'Самовывоз',
  PLACEHOLDER_ADORNMENT_HEIGHT: 'мм.',
  PLACEHOLDER_ADORNMENT_LENGTH: 'мм.',
  PLACEHOLDER_ADORNMENT_VOLUME: 'м3',
  PLACEHOLDER_ADORNMENT_WEIGHT_GROSS: 'кг.',
  PLACEHOLDER_ADORNMENT_WEIGHT_NET: 'кг.',
  PLACEHOLDER_ADORNMENT_WIDTH: 'мм.',
  PLACEHOLDER_CARGOPACK_MARK: 'Маркировка грузового места',
  PLACEHOLDER_CARGOPACK_NAME: 'Название грузового места',
  PLACEHOLDER_CARGOPACK_NUMBER: 'Номер грузового места',
  PLACEHOLDER_HEIGHT: 'Высота',
  PLACEHOLDER_LENGTH: 'Длина',
  PLACEHOLDER_VOLUME: 'Объем',
  PLACEHOLDER_WEIGHT_GROSS: 'Масса брутто',
  PLACEHOLDER_WEIGHT_NET: 'Масса нетто',
  PLACEHOLDER_WIDTH: 'Ширина',
  PLACES_AMOUNT: 'Кол-во мест',
  PLACE_AN_ORDER: 'Оформить заказ',
  PLAN_DELIVERY_DATE: 'Дата доставки план',
  PLAN_EXECUTION: 'Плановое исполнение',
  PLAN_SHIPMENT_DATE: 'Дата отгрузки план',
  PLEASE_TRY_AGAIN_LATER: 'Пожалуйста, попробуйте позже.',
  POINT_HAS_ADDITIONAL_SERVICES: 'Для пункта указаны доп. услуги:',
  POSITIONALLY: 'Попозиционно',
  POSITION_NAME: 'Наименование позиции',
  POSITIVE_EXPERIENCE: 'Положительный опыт',
  PREQUALIFICATIONS: 'Предквалификации',
  PREQUALIFIED_PERFORMERS_LIST: 'Предквалифицированный список исполнителей',
  PREQUALIFIED_PERFORMERS_LIST_TOOLTIP:
    'В предквалифицированный список включены только исполнители удовлетворяющие следующим условиям:',
  PRICE: 'Цена',
  PRICE_FOR_UNIT_NO_VAT: 'Стоим-ть за ед. изм. без НДС',
  PRICE_NO_VAT: 'Цена по прайсу без НДС',
  PRICE_PER_UNIT: 'Цена за единицу без НДС',
  PRICE_TOTAL: 'Итого по прайсу',
  PRICE_TOTAL_NO_VAT: 'Стоимость всего без НДС',
  PRICE_TOTAL_VALUE: 'Стоимость всего',
  PRICE_TYPE: 'Прейскурант',
  PRICE_UNIT: 'Цена за ед.',
  PRICE_UNIT_WITHOUT_VAT: 'Цена за ед. без НДС',
  PRICE_UNIT_WITHOUT_VAT_NOMENCLATURE: 'Цена за ед. без НДС (номенклатура)',
  PRIMARY_LETTER_DATE: 'Дата письма-заявки',
  PRIMARY_LETTER_NUMBER: 'Письмо-заявка первичное',
  PRINCIPAL: 'Принципал',
  PROCUREMENT_MANAGEMENT: 'Управление закупочной деятельностью',
  PRODUCER: 'Производитель',
  PRODUCTION_SCHEDULE_BUTTONS_IS_LOCKED:
    'Невозможна работа с графиком, т.к. для спецификации создано неподписанное доп. соглашение',
  PRODUCTION_SCHEDULE_CREATE_BUTTON_IS_LOCKED:
    'Невозможно создать график производства, т.к. для спецификации создано неподписанное доп. соглашение',
  PRODUCTION_SCHEDULE_IS_LOCKED:
    'График производства заблокирован до момента подписания или отмены доп. соглашения в рамках родительской спецификации',
  PROJECT: 'Проект',
  PROJECTS: 'Проекты',
  PROJECT_BASED: 'Проектный',
  PROJECT_FIELD_IS_REQUIRED: 'Поле "Проект" обязательно для заполнения',
  PROJECT_MTR: 'Проектный МТР',
  PROPOSALS_STEP: 'Шаг на подачу предложений',
  PROVIDING_NO_VAT: 'Предоставление ПС за ед., без НДС',
  PROVISION_OF_SERVICE: 'Оказание услуги',
  PURCHASE: 'Закупка',
  PURCHASES: 'Закупки',
  PURCHASE_COST_WITHOUT_VAT: 'Стоимость закупки без НДС',
  PURCHASE_NAME: 'Наименование закупки',
  PURCHASE_NUMBER: 'Номер закупки',
  PURCHASE_STATUS: 'Статус закупки',
  PUT_ON_COMISSION_BLOCKING_ERROR: 'Предложение поставщика должно покрывать все потребности закупки',
  QR_CODE: 'QR-код',
  QTY: 'шт',
  QUANTITY: 'Количество',
  QUANTITY_APPROVED: 'Количество утверждено',
  QUANTITY_IN_CURRENT_SCHEDULE: 'Кол-во в текущем графике',
  QUANTITY_SHORT: 'Кол-во',
  RAILWAY_CODE: 'Код ЖД станции:',
  RAILWAY_CODE_DECLARED: 'Код ЖД станции (заявлено):',
  RAILWAY_DEADLOCK: 'ЖД тупик',
  RAILWAY_STATION: 'ЖД станция',
  RAILWAY_STATION_NAME: 'Название ЖД станции:',
  RAILWAY_STATION_NAME_DECLARED: 'Название ЖД станции (заявлено):',
  RAIL_REQUIREMENTS: 'Требования к ЖД перевозке',
  RAIL_REQUIREMENTS_BLOCK: 'К ЖД перевозке',
  RAIL_TRANSPORTATION: 'ЖД перевозка',
  RATE_PER_UNIT_NO_VAT: 'ЖД тариф за ед. ПС, без НДС',
  RECALCULATE_CART: 'Пересчитать корзину',
  RECEIVED_OFFERS: 'Получено предложений',
  RECLAIM: 'Вернуть',
  RECOVER_MULTILNG: 'Восстановить/Recover',
  REDUCTION_INFO_TEXT:
    'Автоматическое формирование справки по наименьшей итоговой стоимости предложения участника торгов',
  REDUCTION_PERIOD: 'Период проведения редукциона (рассчитывается автоматически, в часах)',
  REDUCTION_TOOLTIP:
    '"Редукцион (на понижение ставок)" является одним из типов сбора предложений, когда продавец устанавливает наибольшую стоимость контракта, а исполнитель уменьшает цену кратно заданному шагу, до тех пор пока торги не будут завершены или не будет достигнуто пороговое значение.',
  REDUCTION_TYPE: 'Редукцион (на понижение ставок)',
  REFERENCE: 'Справка',
  REFUSAL_TO_CONSIDER: 'Отказ от рассмотрения',
  REFUSED_TO_PARTICIPATE: 'Отказалось от участия:',
  REFUSE_TO_EXECUTE: 'Отказаться от исполнения по маршруту',
  REFUSE_TO_EXECUTE_ADDITIONAL_SERVICE: 'Отказаться от исполнения доп. услуг',
  REFUSE_TO_PARTICIPATE: 'Отказаться от участия',
  REGION_RF: 'Регион РФ',
  REGISTER: 'Письмо-заявка',
  REGISTRATION_OF_BANK_GUARANTEES: 'Оформление банковских гарантий и других финансовых услуг!',
  REJECT: 'Отклонить',
  REJECTION_REASON: 'Причина отказа',
  REJECT_AN_OFFER: 'Отклонить предложение?',
  REJECT_CONTRACT: 'Расторгнуть договор',
  REJECT_CONTRACT_QUESTION: 'Расторгнуть договор?',
  REJECT_CONTRACT_TEXT: 'Договор привязан к закупке.',
  REJECT_OFFER: 'Отклонить предложение',
  REJECT_OFFER_MODAL_TEXT: 'Заказ будет возвращен в статус “Ожидает ответа поставщика”',
  REJECT_OFFER_PERFORMER_MODAL_TITLE: 'Отказаться от участия?',
  REJECT_REASON: 'Причина отклонения',
  REMAINS_TO_END_AUCTION: 'До завершения проведения аукциона осталось',
  REMAINS_TO_END_REDUCTION: 'До завершения проведения редукциона осталось',
  REMOVED_ROUTES: 'Удалены маршруты:',
  REMOVE_ALL_REGIONS: 'Удалить все регионы',
  REMOVING_NEED_FROM_ADDITIONAL_AGREEMENT: 'Исключение потребности из доп соглашения',
  REMOVING_NEED_FROM_PURCHASE: 'Исключение потребности из закупки',
  REMOVING_NEED_MESSAGE: 'Выбранные потребности будут исключены в новую закупку после подписания ДС.',
  REPEAT_NEW_PASSWORD_MULTILNG: 'Повторите новый пароль/Repeat new password',
  REQUEST: 'Заявка',
  REQUIRED: 'Требуется',
  REQUIRED_RADIO: 'Выберите один из вариантов',
  REQUIRED_TO_FILL: 'Обязательно для заполнения',
  REQUIREMENTS: 'Требования',
  REQUIRES_CONFIRMATION: 'Требует подтверждения',
  RESEND_EN: 'Resend?',
  RESEND_IN_EN: 'Resend in',
  RESEND_IN_RU: 'Выслать повторно через',
  RESEND_RU: 'Выслать повторно?',
  RESOURCES_AUTO_NEGOTIATION: 'Автосогласование ресурсов',
  RESOURCES_FOR_NEGOTIATION: 'Ресурсы для согласования',
  RESPONSIBLE: 'Ответственный',
  RESTORE: 'Восстановить',
  RESTRICTION_SUBMISSION_TITLE: 'Ограничение на подачу предложений',
  ROAD_REQUIREMENTS: 'Требования к автоперевозке',
  ROAD_REQUIREMENTS_BLOCK: 'К автоперевозке',
  ROAD_TRANSPORTATION: 'Автоперевозка',
  ROLE_IN_MODULE: 'Роль в модуле',
  ROLLING_STOCK: 'Подвижной состав',
  ROLLING_STOCK_AMOUNT: 'Кол-во подвижного состава',
  ROLLING_STOCK_AND_TARIFFS: 'ПС и тарифы',
  ROLLING_STOCK_COUNT: 'Кол-во ПС',
  ROLLING_STOCK_INFO_TOTAL: 'Стоимость ЖД перевозки, без НДС',
  ROLLING_STOCK_NOT_ADD: 'Подвижной состав не добавлен',
  ROLLING_STOCK_SUBTOTAL_PRICE: 'Стоимость всего по типу ПС, без НДС',
  ROLLING_STOCK_TYPE: 'Тип подвижного состава',
  ROLLING_STOCK_TYPE_SHORT: 'Тип ПС',
  ROUTE: 'Маршрут',
  ROUTES: 'Маршруты',
  ROUTES_NOT_FORMED: 'Маршруты не сформированы',
  ROUTE_ADD: 'Добавить маршрут',
  ROUTE_ASSIGN: 'Назначить маршрут',
  ROUTE_CARGO_LIST: 'Список грузомест маршрута',
  ROUTE_DENOMINATION: 'Наименование маршрута',
  ROUTE_LIST: 'Список маршрутов',
  ROUTE_NAME: 'Название маршрута',
  ROUTE_ORGANIZATION: 'Организация:',
  ROUTE_POINTS: 'Пункты маршрута',
  RUBLE: 'Рубль',
  RUSSIAN_RUBLE: 'Российский рубль',
  SANITAZION_NEEDED: 'Санитарная обработка',
  SANITAZION_NEEDED_RAIL: 'ЖД санитарная обработка',
  SANITAZION_NEEDED_ROAD: 'ЖД санитарная обработка',
  SAVE: 'Сохранить',
  SAVE_ERROR: 'Ошибка сохранения данных',
  SAVE_MULTILNG: 'Сохранить/Save',
  SAVING_CHANGES: 'Сохранение изменений',
  SAVING_CHANGES_PROGRESS: 'Сохранение изменений...',
  SCHEDULED_QUANTITY: 'Кол-во в графике',
  SEARCH_PERFORMERS_PLACEHOLDER: 'Введите название или ИНН/КПП',
  SEARCH_REGIONS_PLACEHOLDER: 'Название или код региона',
  SECTOR_CODE: 'Код сектора',
  SECTOR_CODE_EXAMPLE: 'Пример кода сектора: С01',
  SECTOR_INDEX: 'Индекс сектора',
  SECTOR_TYPE: 'Тип сектора',
  SECURITY: 'Охрана',
  SELECTED_CATEGORIES_WILL_BE_DELETE_CONTINUE_QN: 'Выбранные категории будут помечены на удаление. Продолжить?',
  SELECTED_CATEGORY_WILL_BE_RESTORE_CONTINUE_QN: 'Выбранная категория будет восстановлена. Продолжить?',
  SELECTED_ITEMS_ARE_NOT_AVAILABLE_FOR_ORDER: 'Выбранные позиции недоступны к заказу.',
  SELECTED_OFFER_WILL_BE_WITHDRAWN_CONTINUE_QN: 'Выбранное предложение будет отозвано. Продолжить?',
  SELECTED_PERFORMERS: 'Выбранные исполнители:',
  SELECTED_REGIONS: 'Выбранные регионы:',
  SELECTED_ROUTE_WILL_BE_REMOVED_FROM_THE_LIST_OF_ROUTES: 'Выбранный маршрут будет удален из списка маршрутов торгов.',
  SELECTED_SERVICE_WILL_BE_DELETED: 'Выбранная услуга будет удалена',
  SELECTED_STORAGE_BASE_BE_DELETED: 'Выбранная база хранения будет удалена без возможности её восстановления.',
  SELECTING_NOMENCLATURE: 'Выбор номенклатуры',
  SELECTING_NOMENCLATURES: 'Выбор номенклатур',
  SELECT_APPROVER_TO_SEND_ANALYTICAL_REFERENCE:
    'Для отправки аналитической справки на согласование необходимо выбрать минимум одного согласующего.',
  SELECT_CONTRACT_TYPE: 'Сначала выберите вид договора',
  SELECT_DISCOUNT_ITEMS: 'Выберите позиции для применения скидки',
  SELECT_PACKING_SLIPS_WITHOUT_TRANSPORT_ORDER: 'Выберите одну или более отгрузочные ведомости без заявки',
  SELECT_PERFORMERS: 'Подобрать исполнителей',
  SEND_COUNT: 'Подано предложений:',
  SEND_FOR_APPROVAL: 'Отправить на согласование',
  SEND_TO_CHAT: 'Отправить в чат',
  SEPARATELY_FOR_PAYMENT: 'отдельно к оплате',
  SEQUENTIAL: 'Последовательное',
  SERIES: 'Серия',
  SERVER_IS_NOT_RESPONDING: 'Сервер не отвечает.',
  SERVICE: 'Услуга',
  SETUP_TRADING_CONDITIONS: 'Настроить условия торгов',
  SETUP_TRADING_MODAL_TITLE: 'Настройка условий торгов',
  SHELVING_CODE: 'Код стеллажа',
  SHELVING_CODE_EXAMPLE: 'Пример кода стеллажа: Д01',
  SHIPPING_ADDRESS: 'Адрес отгрузки',
  SHIPPING_STATEMENT_NUMBER: 'Отгрузочная ведомость',
  SHORT_NAME: 'Краткое наименование',
  SHOW_BEST_OFFER: 'Отобразить наилучшее предложение исполнителям',
  SIDE_OF_CONSIDERATION: 'Сторона рассмотрения',
  SIDE_RACK: 'Съемные боковые стойки',
  SIGNED: 'Подписано',
  SIGN_DATE: 'Дата подписания',
  SIGN_ECP: 'Подпись ЭЦП',
  SIGN_IS_ABSENT: 'Подпись отсутствует',
  SIGN_IS_PRESENT: 'Подпись присутствует',
  SOME_ITEMS_IN_YOUR_CART_ARE_NOT_AVAILABLE_FOR_ORDER: 'Некоторые позиции в вашей корзине недоступны к заказу.',
  SOME_PRODUCTS_ARE_NOT_AVAILABLE_FOR_CHECKOUT: 'Некоторые товары недоступны к оформлению',
  SOURCE: 'Источник',
  SOURCE_ADDRESS: 'Отправление',
  SOURCE_NAME: 'Наименование источника',
  SOURCE_POINT_STATION: 'Станция отправления',
  SPECIAL_DRESS: 'Спецодежда',
  SPECIAL_EQUIPMENT: 'Специальное оборудование',
  SPECIAL_PROJECT: 'Специальный проект',
  SPECIAL_PROJECT_CREATE: 'Создать спец. проект',
  SPECIAL_PROJECT_CREATE_TITLE: 'Создание спец. проект',
  SPECIFICATION: 'Спецификация',
  SPECIFICATIONS: 'Спецификации',
  SPECIFICATION_ORIGINAL: 'Оригинал спецификации',
  SPECIFICATION_TERMINATION_ERROR: 'Ошибка при расторжении спецификации',
  SPECIFICATION_TERMINATION_IS_LOCKED:
    'Невозможно расторгнуть спецификацию, т.к. для спецификации создано неподписанное доп. соглашение',
  SPECIFY_THE_DISCOUNT_AMOUNT_FOR_THE_SELECTED_PRODUCT_ITEMS: 'Укажите размер скидки для выбранных товарных позиций',
  SPECIFY_TIME_IN_TIME_ZONE: 'Укажите время в часовом поясе',
  STAGE: 'Этап',
  START_ACTION: 'Начало действия',
  START_COLLECTING_OFFERS: 'Начать сбор предложений',
  START_COLLECTION_OFFERS_TOOLTIP:
    'Установите тип подачи предложений и добавьте хотя бы одного исполнителя к списку приглашенных к торгам.',
  START_DATE: 'Дата начала действия',
  START_DATE_FACT: 'Дата начала факт',
  START_DATE_PLAN: 'Дата начала, план',
  START_POINT: 'Начальный пункт',
  START_WORK_DATE: 'Дата начала работ',
  START_WORK_DATE_FROM_OFFER: 'Дата начала работ по предложению',
  START_WORK_DATE_FROM_OFFER_TOOLTIP:
    'Отображается наиболее ранняя дата из всех указанным по маршрутам (в поле дата начала работ).',
  STATUS: 'Статус',
  STATUS_COST: 'Статус справки о стоимости',
  STATUS_OF_CONCLUSION: 'Статус заключения договора',
  STATUS_OF_MY_ORGANIZATION: 'Статус моей организации',
  STATUS_ON_COMPILING_OR_COMPILED: 'статус "Сборка" или "Собрана"',
  STORAGE: 'Склад',
  STORAGE_BASE: 'База хранения',
  STORAGE_BASES: 'Базы хранения',
  STORAGE_BASE_NAME: 'База хранения',
  STORAGE_PORTAGE_TERMS: 'Условия хранения и транспортировки',
  STREET: 'Улица',
  SUBCATEGORY: 'Подкатегория',
  SUBMIT_AN_OFFER: 'Отправить предложение',
  SUBMIT_FOR_APPROVAL: 'Вынести на согласование',
  SUBREQUEST: 'Субзаявка',
  SUBREQUESTS: 'Субзаявки',
  SUBREQUEST_CREATE: 'Создание субзаявки',
  SUBTOTAL_PRICE: 'Ст-ть всего по типу ТС, без НДС',
  SUCCESSFUL_OFFER_SENT: 'Предложение успешно отправлено',
  SUGGESTED: 'Предложено',
  SUGGESTED_NOMENCLATURE: 'Предлагаемая номенклатура',
  SUM: 'Сумма',
  SUMMARY: 'Общее',
  SUMMARY_LIST: 'Сводный перечень предложений',
  SUPPLIER: 'Поставщик',
  SUPPLIERS_INVITED: 'Приглашено поставщиков:',
  SUPPLY: 'Поставка',
  SUPPLY_ADDRESS: 'Адрес поставки',
  SUPPLY_DATE: 'Дата поставки',
  SURFACE_TOTAL_CALCULATED_M2: 'Общая площадь рассчитанная, м2',
  SURFACE_TOTAL_CORRECTED_M2: 'Общая площадь исправленная, м2',
  SURFACE_TOTAL_M2: 'Общая площадь, м2',
  TARIFF: 'Тариф',
  TASKS: 'Задачи',
  TASK_TYPE: 'Тип задачи',
  TAX_ID: 'TaxID',
  TEMPERATURE_CHECK: 'Температурный чек',
  TEMPERATURE_FROM: 'Температурный режим от',
  TEMPERATURE_FROM_RAIL: 'ЖД температурный режим от',
  TEMPERATURE_FROM_ROAD: 'Авто температурный режим от',
  TEMPERATURE_TO: 'Температурный режим до',
  TEMPERATURE_TO_RAIL: 'ЖД температурный режим до',
  TEMPERATURE_TO_ROAD: 'Авто температурный режим до',
  TEMPLATE_ADDTIONAL_AGREEMENT_ITEMS: 'Шаблон импорта количества и цен номенклатур',
  TEMPORARILY_UNAVALIBLE_ERROR:
    'На текущий момент один из сервисов недоступен. Обратитесь в техподдержку или повторите попытку позднее.',
  TERMLESS: 'Бессрочный',
  THERE_ARE: 'Есть',
  THE_FINAL_COST_OF_THE_BATCH_WILL_BE_CALCULATED_AFTER_PLACING_THE_ORDER:
    'Финальная стоимость партии будет рассчитана после оформления заказа.',
  THE_VALUE_MUST_NOT_BE_GREATER_THAN: 'Значение не должно быть больше',
  THRESHOLD_VALUE: 'Пороговое значение (RUB, без НДС)',
  TIER_NUMBER: 'Номер яруса',
  TIME: 'Время',
  TIME_TO_EXTEND_THE_AUCTION: 'Время на продление проведения аукциона, в минутах',
  TIME_TO_EXTEND_THE_REDUCTION: 'Время на продление проведения редукциона, в минутах',
  TOLERANCE_COUNT: 'Толеранс',
  TOLERANCE_SUM_NO_VAT: 'Сумма толеранса без НДС',
  TOLERANCE_SUM_VAT: 'Сумма толеранса с НДС',
  TOLERANCE_TOTAL_COUNT: 'Всего с толерансом',
  TOLERANCE_VAT: 'НДС толеранса',
  TOP_BEAM: 'Верхняя съемная балка',
  TOTAL: 'Итого',
  TOTAL_CARGO_IN_ROUTE: 'Всего грузомест в маршруте:',
  TOTAL_GOODS: 'Всего товаров',
  TOTAL_LINES: 'Всего строк: ',
  TOTAL_NO_VAT: 'Итого без НДС',
  TOTAL_NUMBER: 'Общее количество',
  TOTAL_ON_ROUTE: 'Итого по маршруту',
  TOTAL_PAYABLE: 'Итого к оплате',
  TOTAL_PAYED: 'Оплачено всего',
  TOTAL_PRICE: 'Итого по прайсу',
  TOTAL_PRICE_EXCLUDE_VAT: 'Итого в заказе без НДС',
  TOTAL_PRICE_INCLUDE_VAT: 'Итого в заказе с НДС',
  TOTAL_PRICE_NO_VAT: 'Итого по прайсу без НДС',
  TOTAL_PRICE_VAT: 'Итого по прайсу с НДС',
  TOTAL_SUM_NO_VAT: 'Сумма без НДС',
  TOTAL_SUM_VAT: 'Сумма с НДС',
  TOTAL_VAT: 'Итого с НДС',
  TOTAL_VOLUME_CARGO: 'Общим объемом:',
  TOTAL_WEIGHT_CARGO: 'Общим весом(нетто/брутто):',
  TOTAL_WEIGHT_GROSS: 'Общая масса брутто, кг',
  TOTAL_WEIGHT_NET: 'Общая масса нетто, кг',
  TO_CREATE_APPLICATION_EACH_PACKING_SLIP_MUST_HAVE: 'Чтобы создать заявку, каждая отгрузочная ведомость должна иметь:',
  TO_CREATE_APPLICATION_EACH_PACKING_SLIP_PURCHASE_MUST_BE_PICKUP:
    'Чтобы создать заявку, в каждой закупке должен быть указан "Самовывоз" в качестве способа поставки',
  TO_CREATE_APPLICATION_PACKING_SLIP_MUST_HAVE: 'Чтобы создать заявку, отгрузочная ведомость должна иметь:',
  TO_CREATE_APPLICATION_PACKING_SLIP_PURCHASE_MUST_BE_PICKUP:
    'Чтобы создать заявку, в спецификации должен быть указан "Самовывоз" в качестве способа поставки',
  TRACKING_STATUS: 'Статус отслеживания',
  TRACK_LABELS_ERROR:
    'Внимание. В процессе выполнения запроса произошла ошибка. Обратитесь в техподдержку или повторите попытку позднее',
  TRACK_LABELS_NAME: 'Название трек-метки',
  TRACK_LABELS_NUMBER: 'Номер трек-метки',
  TRACK_LABELS_REMOVE_MODAL_MESSAGE: 'Выбранная трек-метка будет помечена на удаление.',
  TRACK_LABELS_REMOVE_MODAL_TITLE: 'Пометить на удаление трек-метку?',
  TRANSPORTATION_APPLICATION: 'Заявка на перевозку',
  TRANSPORTATION_DISTANCE_KM: 'Расстояние перевозки, км',
  TRANSPORTATION_REGION: 'Регион перевозок',
  TRANSPORTATION_REGION_TOOLTIP:
    'При указании региона перевозок, системой будут предложены только те исполнители, у которых указаны соответствующие значения в поле "Регион перевозок" в карточке исполнителя. Иначе - все исполнители.',
  TRANSPORTATION_TYPE: 'Тип перевозок',
  TRANSPORTATION_TYPE_TOOLTIP:
    'При указании типа перевозок, системой будут предложены только те исполнители, у которых указаны соответствующие значения в поле "Тип перевозок" в карточке исполнителя. Иначе - все исполнители.',
  TRANSPORT_AMOUNT: 'Кол-во ТС',
  TRANSPORT_EXPEDITION: 'Транспортная экспедиция',
  THE_LIST_OF_CATEGORIES_WILL_BE_COMPLETELY_OVERWRITTEN:
    'Список категорий будет полностью перезаписан и обновлен в соответствии с загружаемым файлом. Убедитесь, что в файле указан полный список категорий.',
  TRIP_AMOUNT: 'Кол-во рейсов',
  TRY_UPLOADING_ANOTHER_FILE: 'Попробуйте загрузить другой файл.',
  TYPE: 'Тип',
  UNAVAILABLE_ADD_CARGO: 'Невозможно добавить грузоместа без маршрутов',
  UNION: 'Объединенная',
  UNION_REQUEST_NUMBER: 'Объединенная заявка',
  UNIT: 'Ед. изм.',
  UNIT_PRICE: 'Ст-ть за 1 рейс, без НДС',
  UNKNOWN_ERROR: 'Неизвестная ошибка',
  UNSAVED_DATA_ATTENTION_TEXT: 'Несохраненные данные будут утеряны. Продолжить?',
  UNSUCCESSFUL_OFFER_SENT: 'Предложение не отправлено',
  UNTITLED: 'Без названия',
  UPDATED: 'Обновлено',
  UPLOAD: 'Загрузить',
  UPLOADING_DOCUMENT: 'Загрузка документа',
  UPLOADING_DOCUMENT_TO_GROUP: 'Загрузка документа к группе номенклатур',
  UPLOAD_CARGOPACKS_TEPLATE: 'Шаблон для загрузки грузомест',
  UPLOAD_GOODS: 'Загрузить товары',
  UPLOAD_OFFER: 'Загрузить предложение',
  UPLOAD_PICTURE: 'Загрузить картинку',
  UPLOADED_BY: 'Загрузил',
  UPLOADED_ORGANIZATION: 'Загрузил. Организация',
  USERS: 'Пользователи',
  USERS_INSTRUCTIONS: 'Инструкции пользователей',
  USER_DOCUMENTATION: 'Пользовательская документация',
  USER_FULL_NAME: 'Ф.И.О. пользователя',
  USER_INSTRUCTIONS_FOR_PLATFORM: 'Инструкции пользователя для работы с платформой',
  USER_MANAGEMENT: 'Управление пользователями',
  USE_ONLY_CYRILLIC_UPPERCASE_AND_NUMBERS: 'Может использоваться только кириллица(заглавные) и цифры',
  VALIDITY: 'Срок действия',
  VALIDATION_PAST_DATE: 'Не может быть прошедшей',
  VAT: 'НДС',
  VAT_BARGAIN_TOOLTIP: 'Отображается указанное в карточке организации значение НДС.',
  VAT_PERCENT: 'НДС, %',
  VAT_SUMM: 'Сумма НДС',
  VECHICLE_AND_COST_TITLE: 'ТС и стоимость рейса',
  VEHICLE_AMOUNT: 'Количество ТС',
  VEHICLE_CAPACITY: 'Грузоподъемность ТС, т',
  VEHICLE_REQUIREMENTS: 'К транспортному средству',
  VEHICLE_VOLUME: 'Объем ТС, м3',
  VIEW_LIST_OF_USERS: 'Просмотреть список пользователей',
  VIEW_ROUTE_CARGOPLACES: 'Просмотреть список грузомест маршрута',
  VOLUME_M3: 'Объем, м3',
  VOLUME_TOTAL_M3: 'Общий объем, м3',
  VERIFICATION_STATUS: 'Статус проверки',
  WAIT_CUSTOMER_CONFIRMATION: 'Ожидайте подтверждения заказчика.',
  WAIT_EMAIL_NOTIFICATION: 'Уведомление будет отправлено на вашу эл. почту.',
  WANT_TO_CLEAR_OFFER: 'Вы действительно хотите очистить предложение?',
  WARNING_DISPATCH_TITLE: 'В добавляемых в торги заявках указаны разные грузоотправители:',
  WARNING_OWNER_TITLE: 'В добавляемых в торги заявках указаны разные заказчики:',
  WARNING_RECEIPT_TITLE: 'В добавляемых в торги заявках указаны разные грузополучатели:',
  WEIGHTING: 'Взвешивание',
  WEIGHT_GROSS: 'Масса брутто, кг',
  CARGO_PRICE_CURRENCY: 'Валюта стоимости груза',
  CARGO_PRICE_VALUE: 'Стоимость груза с НДС',
  WEIGHT_KG: 'Вес, кг',
  WEIGHT_NET: 'Масса нетто, кг',
  WEIGHT_ONE_PLACE_PER_UNIT: 'Вес одного места, кг',
  WEIGHT_SIZE_COLUMN: 'Массогабаритные характеристики',
  WEIGHT_TOTAL_KG: 'Общий вес, кг',
  WIDTH_M: 'Ширина, м',
  WIDTH_MM: 'Ширина, мм',
  WIDTH_ONE_PLACE: 'Ширина одного места, см',
  WITHDRAW: 'Отозвать',
  WITHDRAW_OFFER_QN: 'Отозвать предложение?',
  WITHOUT_VAT: 'без НДС',
  WITH_VAT: 'с НДС',
  WITH_VAT_20: 'с НДС, 20%',
  WORK_DURATION: 'Продолжительность работ:',
  WORK_DURATION_DAYS: 'Срок работ, дней',
  WORK_DURATION_HOURS: 'Продолжительность работ, ч',
  WORK_TYPE: 'Вид работ:',
  WORK_TYPE_LABEL: 'Вид работ',
  WRONG_PHONE_NUBMER_FORMAT: 'Не соответствует формату +71234567890',
  YES: 'Да',
  YOUR_APPLICATION_HAS_BEEN_SENT: 'Ваша заявка успешно отправлена.',
  YOU_WILL_RECEIVE_CONFIRMATION_FROM_THE_SUPPLIER_SHORTLY: 'В ближайшее время вы получите подтверждение от поставщика',
  SERVICE_UNAVAILABLE: 'На текущий момент один из сервисов недоступен. Повторите попытку позднее.',
  MAX_FILES_EXCEEDED: 'Вы можете загрузить не более 25 документов за один раз',
  DOCUMENTS_EXPORT: 'Экспорт документов',
  SELECT_DOCUMENTS_TO_EXPORT: 'Выберите документы из списка, которые необходимо выгрузить из системы',
  CHOOSE_MATCHING_ROUTE: 'Выбор маршрута согласования',
  INTERNAL: 'Внутреннее',
  TO_COUNTERPARTY: 'Контрагенту',
  SHOULD_SEND_TO_COUNTERPARTY_QUESTION: 'Отправить документ контрагенту?',
};
