import { Box, Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import background from '../../../../assets/img/background.png';

interface AuthLayoutStyleProps {
  maxWidth?: string;
}

const useStyles = makeStyles<Theme, AuthLayoutStyleProps>((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: '#F7F7FA',
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      backgroundSize: 'cover',
    },
    wrapper: {
      width: '100%',
      maxWidth: (styleProps) => styleProps?.maxWidth || '334px',
      minHeight: '346px',
      boxShadow: '1px 13px 44px 5px rgba(26, 118, 143, 0.63)',
      backgroundColor: 'rgba(91, 158, 196, 0.35)',
      padding: theme.spacing(6.5, 4.75, 5),
    },
    content: {
      width: '100%',
    },
  })
);

export interface AuthLayoutProps {
  maxWidth?: string;
}

export const AuthLayout: React.FC<PropsWithChildren<AuthLayoutProps>> = (props) => {
  const { maxWidth, children } = props;
  const classes = useStyles({ maxWidth });

  return (
    <Grid className={classes.root} container justify="center" alignItems="center" direction="column">
      <Grid className={classes.wrapper} item>
        <Grid container justify="center" alignItems="center" direction="column">
          <Grid className={classes.content} item>
            <Box>{children}</Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
