import { API } from 'constants/API';
import { ResponseResult, ResponseResultWithValue } from 'models/CommonModels';
import { ServiceBase } from 'services/ServiceBase';
import { VersionAPI } from 'services/versionAPI';
import {
  AddOrganizationAddress,
  DeleteOrganizationAddress,
  OrganizationAddress,
  UpdateOrganizationAddress,
} from '../models/OrganizationAddresses';

export class OrganizationAddresses extends ServiceBase {
  protected static BASE_URL = '/organizationAddresses';

  protected static options = { baseURL: API.ORGANIZATIONS_API + VersionAPI.VERSION10 };

  public static addOrganizationAddress(params: AddOrganizationAddress) {
    return this.post<ResponseResult<number>>('', params, this.options);
  }

  public static updateOrganizationAddress(params: UpdateOrganizationAddress) {
    return this.put<ResponseResult<number>>('', params, this.options);
  }

  public static deleteOrganizationAddress(params: DeleteOrganizationAddress) {
    return this.delete<ResponseResultWithValue>(`/${params.organizationAddressId}`, null, this.options);
  }

  public static getOrganizationAddress(id: number) {
    return this.get<OrganizationAddress>(`/${id}`, {}, this.options);
  }
}
