import { Theme } from '@material-ui/core';
import lahtaTheme from './lahtaTheme';

export type Themes = 'lahta';

export const themes: Record<Themes, Theme> = {
  lahta: lahtaTheme,
};

export const CurrentTheme = (theme: keyof Record<Themes, Theme>): Theme => themes[theme];
