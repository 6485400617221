const locations = {
  market: '/market-service',
  mdm: '/mdm-service',
  filestorage: '/filestorage-service',
  messenger: '/messenger-hub-service',
  bid: '/bid-service',
  catalog: '/catalog-service',
  contractor: '/contractor-service',
  protocol: '/protocol-service',
  skuimport: '/skuimport-service',
  notificationHub: '/notification-service',
  contract: '/contract-service',
  specification: '/specification-service',
  packing: '/cargopacking-service',
  plan: '/plan-service',
  process: '/process-service',
  resources: '/resources-service',
  needs: '/needs-service',
  permissions: '/permissions-service',
  helpdesk: '/helpdesk-service',
  purchase: '/purchase-service',
  logistic: '/logistics-service',
  nomenclature: '/nomenclature-service',
  trip: '/trip-service',
  audit: '/audit-service',
  organizations: '/organization-service',
  metabase: '/metabase-service',
  documents: '/documents-service',
  escuz: '/escuz-service',
  messengerBusiness: '/messenger-business-service',
  projects: '/projects-service',
  storage: '/storehouse-service',
  aggregation: '/aggregation-service',
  cart: '/cart-service',
  bargain: '/bargain-service',
  businessTask: '/businesstask-service',
  orders: '/orders-service',
  report: '/report-service',
  finfactory: '/finfactory-service',
  ref: '/ref-service',
};

const getEnvVariableValue = (variableName: string): string | undefined => process.env[variableName];

const getEnvVariableValueOrThrowError = (variableName: string): string => {
  const value = process.env[variableName];

  if (!value) {
    throw new Error(`Environment variable ${variableName} is not defined.`);
  }

  return value;
};

const createRoute = (url: string, point = '/api/'): string => getEnvVariableValue('REACT_APP_SERVER_URL') + url + point;

const generateApi = () => {
  return {
    URL: getEnvVariableValueOrThrowError('REACT_APP_SERVER_URL'),

    AGGREGATION_API: createRoute(locations.aggregation),
    AUDIT_API: createRoute(locations.audit),
    BARGAIN_API: createRoute(locations.bargain),
    BUSINESS_TASK_API: createRoute(locations.businessTask),
    CART_API: createRoute(locations.cart),
    CATALOG_API: createRoute(locations.catalog),
    CHAT_API: createRoute(locations.messenger),
    CONTRACT_API: createRoute(locations.contract),
    CONTRACTOR_API: createRoute(locations.contractor),
    DOCUMENTS_API: createRoute(locations.documents),
    ESCUZ_API: createRoute(locations.escuz),
    FILESTORAGE_API: createRoute(locations.filestorage),
    FINFACTORY_API: createRoute(locations.finfactory),
    HELP_DESK_API: createRoute(locations.helpdesk),
    LOGISTIC_API: createRoute(locations.logistic),
    MARKET_API: createRoute(locations.market),
    MDM_API: createRoute(locations.mdm),
    MESSENGER_API: createRoute(locations.messenger, '/messenger'),
    MESSENGER_BUSINESS_API: createRoute(locations.messengerBusiness),
    METABASE_API: createRoute(locations.metabase),
    NEEDS_API: createRoute(locations.needs),
    NOMENCLATURE_API: createRoute(locations.nomenclature),
    NOTIFICATIONS_HUB: createRoute(locations.notificationHub, '/hub'),
    ORDERS_API: createRoute(locations.orders),
    ORGANIZATIONS_API: createRoute(locations.organizations),
    PACKING_API: createRoute(locations.packing),
    PERMISSIONS_API: createRoute(locations.permissions),
    PLAN_API: createRoute(locations.plan),
    PROCESS_API: createRoute(locations.process),
    PROJECTS_API: createRoute(locations.projects),
    PROTOCOL_API: createRoute(locations.protocol),
    PURCHASE_API: createRoute(locations.purchase),
    REPORT_API: createRoute(locations.report),
    RESOURCES_API: createRoute(locations.resources),
    SKUIMPORT_API: createRoute(locations.skuimport),
    SPECIFICATION_API: createRoute(locations.specification),
    STOCK_EXCHANGE_API: createRoute(locations.bid),
    STORAGE_API: createRoute(locations.storage),
    TRIP_API: createRoute(locations.trip),
    USER_NOTIFICATIONS_API: createRoute(locations.notificationHub),
    USER_NOTIFICATIONS_HUB: createRoute(locations.notificationHub, '/usernotificationhub'),
    REF_API: createRoute(locations.ref),

    PROXY: getEnvVariableValue('REACT_APP_PROXY_URL'),

    SSO_API: getEnvVariableValueOrThrowError('REACT_APP_SSO_API_URL'),
    SSO_FRONT: getEnvVariableValueOrThrowError('REACT_APP_SSO_FRONT_URL'),

    BIDS_MODULE: getEnvVariableValueOrThrowError('REACT_APP_BIDS_MODULE_URL'),
    LOGISTIC_MODULE: getEnvVariableValueOrThrowError('REACT_APP_LOGISTICS_MODULE_URL'),
    CATALOG_MODULE: getEnvVariableValueOrThrowError('REACT_APP_CATALOG_MODULE_URL'),

    LOGISTIC_CONTROLLER: getEnvVariableValue('REACT_APP_LOGISTICS_CONTROLLER_URL'),
    RULESTREAM_API: getEnvVariableValueOrThrowError('REACT_APP_RULESTREAM_API_URL'),

    BLITZ_API: getEnvVariableValueOrThrowError('REACT_APP_BLITZ_API_URL'),
    PLATFORM_URL: getEnvVariableValueOrThrowError('REACT_APP_MARKET_MODULE_URL'),
  };
};

export const API = generateApi();
