export const LOGIN_PAGE = 'login';
export const INVITE_PAGE = 'invite';
export const RECOVERY_PAGE = 'recovery';
export const LOGOUT_PAGE = 'logout';
export const ACCESS_DENIED_PAGE = 'access-denied';
export const NOT_FOUND_PAGE = 'not-found';
export const NOMENCLATURE = 'nomenclature';

export const DESKTOP_PAGE = 'desktop';
export const ADMIN_PAGE = 'admin';
export const PREQUALIFICATIONS_PAGE = 'prequalifications';
export const PREQUALIFICATIONS_CARD_TAB = 'prequalification';
export const USER_PAGE = 'user';
export const USERS_PAGE = 'users';
export const CATEGORIES_PAGE = 'categories';
export const CATEGORY_CARD_TAB = 'category';
export const USERS_GROUPS = 'groups';
export const SETTINGS_PAGE = 'settings';
export const RULESTREAM_PAGE = 'rulestream';
export const BARGAIN_PAGE = 'bargain';
export const LIST = 'list';
export const CONTRACTS_LIST = 'contractslist';
export const VIEW = 'view';
export const BARGAIN_CARD = 'bargainCard';
export const GENERAL = 'general';
export const CARGO_PLACES = 'cargoplaces';
export const ROUTE = 'route';
export const ADDITIONAL_SERVICES = 'additional-services';
export const GENERAL_OFFER = 'general-offer';
export const MARKET = 'market';
export const MARKET_PERFORMER = 'market-performer';
export const ANALYTICAL_REFERENCE = 'reference';
export const REQUIREMENTS = 'requirements';
export const GENERAL_REQUIREMENT = 'general-requirement';
export const ROLLING_STOCK = 'rolling-stock';
export const REGISTER = 'register';
export const TRIP_POINTS = 'trip-points';

export const ORGANIZATIONS_PAGE = 'organizations';
export const PERFORMERS_PAGE = 'performers';
export const ORGANIZATIONS_PAGE_GENERAL = 'general';
export const ORGANIZATIONS_PAGE_USERS = 'users';
export const ORGANIZATIONS_PAGE_CONTACTS = 'contacts';
export const DOCUMENTS_PAGE = 'documents';
export const ORGANIZATIONS_PAGE_RESOURCE = 'resource';
export const ORGANIZATIONS_PAGE_REQUISITIES = 'requisities';
export const ORGANIZATIONS_PAGE_ADDRESSES = 'addresses';
export const ORGANIZATIONS_PAGE_ROUTE = 'route';
export const ORGANIZATIONS_PAGE_OPERATOR_INTERACTION = 'operator-interaction';

export const RESOURCES_AUTOMOBILE = 'automobile';
export const RESOURCES_SPECIALSCAR = 'scpecialscar';
export const RESOURCES_TRAILERS = 'trailers';
export const RESOURCE_DRIVER = 'driver';
export const RESOURCES_WAREHOUSES = 'warehouses';

export const MARKETPLACE_PAGE = 'marketplace';
export const SPECIFICATIONS_PAGE = 'specifications';
export const ADDITIONAL_AGREEMENT_PAGE = 'additional-agreements';
export const QUESTIONNAIRE_PAGE = 'questionnaire';
export const SUPPORT_PAGE = 'support';

export const BIDS_PAGE = 'bids';

export const LOGISTICS_ROOT = 'logistics';
// TODO: убрать и заменить на LOGISTICS_ROOT после переноса всех вкладок логистики
export const LOGISTICS_REWORK = `${LOGISTICS_ROOT}2`;
export const LOGISTICS_REQUESTS = 'requests';
export const LOGISTICS_SUBREQUESTS = 'subrequests';
export const LOGISTICS_VIEW = 'view';
export const LOGISTICS_REQUEST_GENERAL = 'general';
export const LOGISTICS_TRIPS = 'trips';
export const LOGISTICS_ROAD = 'road';
export const LOGISTICS_RAIL = 'rail';
export const LOGISTICS_MAP = 'map';
export const LOGISTICS_LOGOPERATOR = 'logoperator';
export const LOGISTICS_ROAD_LOGOPERATOR = `${LOGISTICS_ROAD}/${LOGISTICS_LOGOPERATOR}`;
export const LOGISTICS_RAIL_LOGOPERATOR = `${LOGISTICS_RAIL}/${LOGISTICS_LOGOPERATOR}`;

export const PROJECTS_PAGE = 'projects';
export const PROJECT_PAGE = 'project';
export const PROJECT_INFO = 'info';
export const PROJECT_COUNTERPARTIES = 'counterparties';
export const PROJECT_DOCUMENTS = 'documents';
export const PROJECT_OBJECTS = 'objects';
export const PROJECT_TEAM = 'team';
export const PROJECTS_LIST = 'projects-list';
export const OBJECTS_LIST = 'objects-list';

export const OBJECT_PAGE = 'object';
export const OBJECT_INFO = 'info';
export const OBJECT_COUNTERPARTIES = 'counterparties';
export const OBJECT_DOCUMENTS = 'documents';
export const OBJECT_COMMUNICATIONS = 'communications';

export const CATALOG_PAGE = 'catalog';
export const CART_PAGE = 'cart';
export const ORDERS_PAGE = 'orders';
export const MASTER_CATALOGS_PAGE = 'master-catalogs';
export const MASTER_POSITIONS_PAGE = 'master-positions';
export const SPECIAL_REQUIREMENTS_PAGE = 'special-requirements';
export const PASSPORTS_PAGE = 'passports';
export const LABORATORIES_PAGE = 'laboratories';
export const HELP_DESK_PAGE = 'help-desk';
export const ORDER_ITEMS_PAGE = 'order-items';

export const DOCUMENTS_PAGE_LIST = 'documents-list';
export const DOCUMENTS_PAGE_IMPORT = 'import';
export const DOCUMENTS_PAGE_EXPORT = 'export';

export const MASTER_PRODUCTS_PAGE = 'master-products';
export const CONTRAGENTS_PAGE = 'contragents';
export const DESCRIPTION_PAGE = 'description';
export const DETAILS_PAGE = 'details';
export const SPECIFICS_PAGE = 'specifics';

export const BUSINESS_ANALYTICS_PAGE = 'business_analytics';
export const BUSINESS_ANALYTICS_PAGE_DASHBOARD = 'dashboard';
export const BUSINESS_ANALYTICS_PAGE_ACCESS_SETTINGS = 'access_settings';
export const BUSINESS_ANALYTICS_PAGE_USERS = 'users';
export const BUSINESS_ANALYTICS_PAGE_GROUPS = 'groups';
export const BUSINESS_ANALYTICS_PAGE_ROLES = 'roles';

export const STORAGE_PAGE = 'storage';
export const STORAGE_BASES = 'storage-bases';
export const STORAGE_BASE = 'storage-base';

export const SPECIFICATIONS_GOODS_PAGE = 'goods';
export const NOMENCLATURES_PAGE = 'nomenclatures';

export const getRoute = (pages: string[] | string): string => {
  if (pages instanceof Array) {
    return `/${pages.join('/')}`;
  }

  return `/${pages}`;
};
