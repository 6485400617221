import { API } from 'constants/API';
import { BaseResponseResult } from 'models/CommonModels';
import { ServiceBase } from 'services/ServiceBase';
import { VersionAPI } from 'services/versionAPI';
import { UpdateCooperationInfoProps } from '../models/CooperationInfo';

export class CooperationInfo extends ServiceBase {
  protected static BASE_URL = '/cooperation-info';

  protected static options = { baseURL: API.ORGANIZATIONS_API + VersionAPI.VERSION10 };

  public static updateCooperationInfo(props: UpdateCooperationInfoProps) {
    return this.put<BaseResponseResult>('', props, this.options);
  }
}
