import { createEffect, createEvent, createStore } from 'effector';
import { ServiceBase } from 'services/ServiceBase';

const jwtStoreName = 'SSO_JWT';

type JWT = string;

export const onLogout = createEvent('emit logout event');
export const logoutFx = createEffect('Start logout process');
export const setJwt = createEvent<Nullable<JWT>>();

export const $Jwt = createStore<Nullable<JWT>>(null, { name: jwtStoreName });

$Jwt.on(setJwt, (_, payload) => payload);
$Jwt.on(logoutFx.done, () => null);
$Jwt.reset(logoutFx.done);

$Jwt.watch((state) => {
  if (state) {
    ServiceBase.setAuthToken(state);
  }
});

logoutFx.done.watch(() => {
  /* eslint-disable-next-line */
  location.replace(`${location.origin}/login`);
});
