import { API } from 'constants/API';
import { UniversalShortEntity } from 'models/CommonModels';
import { ServiceBase } from 'services/ServiceBase';
import { VersionAPI } from 'services/versionAPI';

export class DocumentFlow extends ServiceBase {
  protected static BASE_URL = '/document-flow';

  protected static options = { baseURL: API.ORGANIZATIONS_API + VersionAPI.VERSION10 };

  public static getDocumentFlowTypes() {
    return this.get<UniversalShortEntity[]>('/types', {}, this.options);
  }
}
