import { createEvent, createStore } from 'effector';
import jwtDecode from 'jwt-decode';
import _lodash from 'lodash';
import PermissionService from 'services/PermissionService';
import { AUTH_STORE } from '../../constants/localStorage';
import { AccessTokenDecoded, User } from '../../services/Accounts/types/accountService';
import { ServiceBase } from '../../services/ServiceBase';
import { fetchPermissionsFx, resetPermission } from './Permission.effects';

export const resetAuthEvent = createEvent('Reset Auth Store');
export const setToken = createEvent<string>('set token');
export const setAdditionalAccess = createEvent<Partial<AdditionalAccess>>('set additional access');

const defaultAuthStore = {
  user: null,
  errors: [],
};

export interface AdditionalAccess {
  hasAccessToMarketplace: boolean;
}

export interface AuthStore {
  user: Nullable<User>;
  errors: string[];
  additionalAccess?: Partial<AdditionalAccess>;
}

export const $Auth = createStore<AuthStore>(defaultAuthStore, {
  name: AUTH_STORE,
});

$Auth
  .on(resetAuthEvent, () => {
    return defaultAuthStore;
  })
  .on(setToken, (state, token) => {
    if (token) {
      const newState = _lodash.cloneDeep(state);
      const jwtDecoded: AccessTokenDecoded = jwtDecode(token);
      newState.user = {
        name: `${jwtDecoded.family_name || ''} ${jwtDecoded.given_name || ''} ${jwtDecoded.middle_name || ''}`,
        access_token: token,
        id_user: jwtDecoded.lahta_acc_id,
        user_data: jwtDecoded,
      };
      return newState;
    }
    return state;
  })
  .on(setAdditionalAccess, (state, payload) => {
    const newState = _lodash.cloneDeep(state);
    newState.additionalAccess = { ...newState.additionalAccess, ...payload };
    return newState;
  });

$Auth.watch((auth) => {
  if (auth.user != null && auth?.user?.access_token) {
    ServiceBase.setAuthToken(auth.user.access_token);
    PermissionService.setPermissionAuthToken(auth.user.access_token);
    fetchPermissionsFx(auth.user.id_user || auth.user.user_data.lahta_acc_id);
  } else {
    resetPermission();
  }
});
