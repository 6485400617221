export const PHONE_FORMAT = '+7 (###) ###-##-##';
export const PHONE_FORMAT_NUMBERS = '+7 (999) 999-99-99';
export const PHONE_PLACEHOLDER = '+7 (000) 000-00-00';
export const PHONE_COUNTRY_CODE = '+7';

export class PhoneFormatter {
  // преобразование номера телефона для режима просмотра из +7########## в +7 (###) ###-##-##
  public static preView = (phoneNumber: string): string => {
    if (phoneNumber) {
      const cleaned = phoneNumber.replace(/\D/g, '');
      const match = cleaned.match(/^(7|)(\d{3})(\d{3})(\d{2})(\d{2})$/);
      if (match) {
        const intlCode = match[1] ? `+${match[1]}` : '';
        return [intlCode, ' (', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
      }
    }
    return '';
  };

  // преобразование номера телефона перед сохранением из +7 (###) ###-##-## или ########## в +7##########
  public static preSave = (phoneNumber: string): string => {
    if (phoneNumber) {
      const cleaned = phoneNumber.replace(/\D/g, '');
      const match = cleaned.match(/^(7|)(\d{10})$/);
      if (match) {
        const intlCode = match[1] ? `+${match[1]}` : PHONE_COUNTRY_CODE;
        return [intlCode, match[2]].join('');
      }
    }
    return '';
  };

  // преобразование номера телефона перед редактированием из +7########## в ##########
  // (убираем +7, потому что эти символы автоматом добавятся при применении маски)
  public static preEdit = (phoneNumber: string): string => {
    if (phoneNumber) {
      const cleaned = phoneNumber.replace(/\D/g, '');
      const match = cleaned.match(/^(7|)(\d{10})$/);
      if (match) {
        return match[2];
      }
    }
    return '';
  };
}
