import { Box, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { InviteErrorConsumer } from '../../features/invite/invite';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontStyle: 'italic',
      color: '#F37A48',
      fontSize: '14px',
      lineHeight: '16px',
      marginTop: '16px',
    },
  })
);

export const InviteErrors: React.FC = () => {
  const classes = useStyles();
  return <InviteErrorConsumer>{(error) => <Box className={classes.root}>{error}</Box>}</InviteErrorConsumer>;
};
