import { Box, Grid } from '@material-ui/core';
import { PHONE_FORMAT } from 'components/shared/helpers/phoneFormatter';
import { FLAT_MESSAGES } from 'constants/messages/flatMessages';
import React from 'react';
import { PatternFormat } from 'react-number-format';
import { AuthInput } from '../../components/AuthInput/AuthInput';
import {
  InviteFormConsumer,
  onChangePassword,
  onChangePasswordConfirm,
  onChangePhone,
} from '../../features/invite/invite';

export const InviteForm: React.FC = () => (
  <InviteFormConsumer>
    {({ password, passwordConfirm, phone }) => (
      <Grid container direction="column">
        <Grid item>
          <Box mb={3}>
            <PatternFormat
              placeholder={FLAT_MESSAGES.PHONE}
              customInput={AuthInput}
              onValueChange={({ value }) => {
                onChangePhone(value);
              }}
              format={PHONE_FORMAT}
              mask="_"
            />
          </Box>
        </Grid>
        <Grid item>
          <Box mb={3}>
            <AuthInput
              type="password"
              value={password}
              placeholder="Пароль"
              onChange={(event) => onChangePassword(event.currentTarget.value)}
            />
          </Box>
        </Grid>
        <Grid item>
          <AuthInput
            type="password"
            placeholder="Повторите пароль"
            value={passwordConfirm}
            onChange={(event) => onChangePasswordConfirm(event.currentTarget.value)}
          />
        </Grid>
      </Grid>
    )}
  </InviteFormConsumer>
);
