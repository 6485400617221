import { PagedResponse } from '@stecpoint/ui-kit/build/models/PagedContext/PagedResponse';
import { AxiosPromise } from 'axios';
import { API } from 'constants/API';
import { BaseResponseResult, ResponseResult, ShortEntity } from 'models/CommonModels';
import { ServiceBase } from 'services/ServiceBase';
import { VersionAPI } from 'services/versionAPI';
import { OrganizationRequest, OrganizationUpdateProps, Role } from '../models/Organizations';

export class Organizations extends ServiceBase {
  protected static BASE_URL = '/organizations';

  protected static options = { baseURL: API.ORGANIZATIONS_API + VersionAPI.VERSION10 };

  public static addOrganization(params: OrganizationRequest) {
    return this.post<ResponseResult<number>>('', params, this.options);
  }

  public static updateOrganization(params: OrganizationUpdateProps) {
    return this.put<BaseResponseResult>('', params, this.options);
  }

  public static getOrganizationTypeByID(organizationID: string) {
    return this.get<ShortEntity<number>[]>(`/${organizationID}/types`, {}, this.options);
  }

  public static deleteOrganization(id: number) {
    return this.delete<BaseResponseResult>(`/${id}`, {}, this.options, true);
  }

  public static getRolesByOrg(organizationId: number) {
    return this.get<Role[]>(`/${organizationId}/roles`, {}, this.options);
  }

  /** Возвращает доступные роли по типам организации */
  public static getOrganizationsRoles(organizationIds: number[]) {
    return this.post<Role[]>('/roles', { organizationIds }, this.options);
  }

  public static async getPagedRolesByOrg(organizationId: number): AxiosPromise<PagedResponse<Role>> {
    const response = await this.getRolesByOrg(organizationId);

    return {
      ...response,
      data: {
        data: response.data,
        totalCount: response.data.length,
      },
    };
  }

  public static patchLogo({ id, link }: { id: string; link: string }) {
    return this.patch(`/${id}/logo`, { link, organizationId: id }, this.options);
  }
}
