import createPalette from '@material-ui/core/styles/createPalette';
import { createMuiTheme } from '@material-ui/core';
import { figmaColors } from '@stecpoint/ui-kit';
import createMyTheme from '../createMyTheme';

import ExitIcon from '../../../assets/img/svg/exit.svg';
import PersonIcon from '../../../assets/img/svg/person_icon.svg';
import HamburgerIcon from '../../../assets/img/svg/gamburger.svg';
import LahtaLogo from '../../../assets/img/svg/logo_lahta.svg';

const defaultTheme = createMuiTheme();

const palette = createPalette({
  primary: {
    main: figmaColors.buttons.main,
    light: '#D0E5FF',
    dark: figmaColors.buttons.hoverFilled,
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#FDD59B',
    light: figmaColors.background.element,
    dark: '#F08507',
    contrastText: '#FFFFFF',
  },
  divider: figmaColors.divider.main,
  error: {
    main: figmaColors.status.errorText,
    light: '#E57373',
    dark: '#D32F2F',
  },
  background: {
    paper: figmaColors.background.white,
    default: figmaColors.background.main,
  },
  text: {
    primary: figmaColors.text.primary,
    secondary: figmaColors.text.secondary,
    disabled: figmaColors.text.disabled,
  },
});

export default createMyTheme({
  typography: {
    fontFamily: ['Montserrat', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(),
  },
  uiKitColors: { ...figmaColors },
  layout: {
    sidebar: {
      width: '64px',
      color: figmaColors.text.disabled,
      hoverColor: figmaColors.actions.menuHover,
      backgroundColor: figmaColors.actions.menuActive,
    },
  },
  images: {
    logo: LahtaLogo,
    icons: {
      person: PersonIcon,
      hamburger: HamburgerIcon,
      exit: ExitIcon,
    },
  },
  palette,

  userStatus: {
    access: {
      background: '#E5EFE5',
      text: '#4A6400',
    },
    accessDenied: {
      background: '#ECF2F7',
      text: '#4682BA',
    },
    delete: {
      background: '#FFE5E5',
      text: '#FF0000',
    },
    accessRequest: {
      background: '#b3d1ff',
      text: '#003380',
    },
  },
  resourceStatus: {
    '100': {
      background: '#CCCED9',
      text: '#FFFFFF',
    },
    '150': {
      background: '#ffa000',
      text: '#FFFFFF',
    },
    '200': {
      background: '#F44336',
      text: '#FFFFFF',
    },
    '300': {
      background: '#FFA000',
      text: '#FFFFFF',
    },
    '400': {
      background: '#44C549',
      text: '#FFFFFF',
    },
  },
  overrides: {
    MuiTable: {
      root: {
        backgroundColor: palette.common.white,
        '& .MuiTableHead-root ': {
          '& tr:nth-child(3)': {
            visibility: 'collapse',
          },
        },
        '& .MuiTableRow-root': {
          backgroundColor: palette.common.white,
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: 500,
        '&.MuiFormLabel-root.Mui-disabled': {
          color: `${palette.text.disabled} !important`,
        },
      },
    },
    MuiTableCell: {
      head: {
        lineHeight: defaultTheme.typography.pxToRem(18),
        color: palette.text.secondary,
        padding: `${defaultTheme.spacing(3, 2, 1, 2)} !important`,
        verticalAlign: 'bottom',
        borderBottom: `1px solid ${figmaColors.text.disabled}`,
        '&>div:first-child>div:first-child>span': {
          fontSize: 12,
        },
      },
      body: {
        padding: `${defaultTheme.spacing(1.75, 2)} !important`,
        fontSize: 14,
      },
      footer: {
        background: palette.background.default,
      },
    },
    MuiLink: {
      root: {
        '&:visited': {
          color: palette.primary.main,
        },
      },
    },
  },
});
