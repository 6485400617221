import { CircularProgress, Grid, createStyles, makeStyles } from '@material-ui/core';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { LOGIN_PAGE, getRoute } from '../../../../constants/pagesNames';
import { AuthButton } from '../../components/AuthButton/AuthButton';
import { AuthLayout } from '../../components/AuthLayout/AuthLayout';
import { $Phone, inviteFx, onChangeToken, onInvite, setInviteError } from '../../features/invite/invite';
import { InviteErrors } from './inviteErrors';
import { InviteForm } from './inviteForm';

interface InviteQueryParams {
  token: string;
  contur: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    button: {
      width: '100%',
      marginTop: '20px',
    },
  })
);

const Invite: React.FC = () => {
  const classes = useStyles();
  const match = useRouteMatch<InviteQueryParams>();
  const $phone = useStore($Phone);
  const invitePending = useStore(inviteFx.pending);

  useEffect(() => {
    if (match?.params.token) onChangeToken(match.params.token);
  }, [match?.params.token]);

  if (!match?.params.token) return <Redirect to={getRoute(LOGIN_PAGE)} />;

  return (
    <AuthLayout>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if ($phone.length <= 11) {
            setInviteError('Поле телефон обязательно для заполнения');
          } else {
            onInvite();
          }
        }}
      >
        <Grid container direction="column" alignItems="center">
          <Grid className={classes.fullWidth} item>
            <InviteForm />
          </Grid>
          <Grid item>
            <InviteErrors />
          </Grid>
          <Grid className={classes.button} item>
            <AuthButton variant="contained" color="primary" type="submit">
              {invitePending ? <CircularProgress size={25} color="secondary" /> : 'Готово'}
            </AuthButton>
          </Grid>
        </Grid>
      </form>
    </AuthLayout>
  );
};

export default Invite;
