import { Invite } from 'components/Auth/api/invite/api';
import { RegistrationParams } from 'components/Auth/api/invite/models';
import { combine, createEffect, createEvent, createStore, merge, sample } from 'effector';
import { createStoreConsumer } from 'effector-react';

type Token = RegistrationParams['token'];
type Password = RegistrationParams['password'];
type PasswordConfirm = RegistrationParams['passwordConfirm'];
type Phone = RegistrationParams['phone'];

export type InviteError = string;

export type InviteFrom = RegistrationParams;

export const onChangeToken = createEvent<Token>('set token event');
export const onChangePhone = createEvent<Phone>('change phone store');
export const onChangePassword = createEvent<Password>('change password store');
export const onChangePasswordConfirm = createEvent<PasswordConfirm>('change password confirm store');

export const onInvite = createEvent('emit confirm invite event');

export const inviteFx = createEffect<RegistrationParams, boolean, string>('Start confirm invite process');
export const setInviteError = createEvent<string>('Set invite error');

const $Password = createStore<Password>('', { name: 'User password' });
$Password.on(onChangePassword, (_, payload) => payload);
$Password.reset(inviteFx.done);

const $PasswordConfirm = createStore<PasswordConfirm>('', {
  name: 'User confirm password',
});
$PasswordConfirm.on(onChangePasswordConfirm, (_, payload) => payload);
$PasswordConfirm.reset(inviteFx.done);

export const $Phone = createStore<Phone>('+7', { name: 'Confirm code' });
$Phone.on(onChangePhone, (_, payload) => `+7${payload}`);
$Phone.on(inviteFx.done, () => '+7');

const $Token = createStore<Token>('', { name: 'Invitation token' });
$Token.on(onChangeToken, (_, payload) => payload);
$Token.reset(inviteFx.done);

export const $inviteForm = combine(
  $Token,
  $Password,
  $PasswordConfirm,
  $Phone,
  (token, password, passwordConfirm, phone): InviteFrom => ({
    token,
    password,
    passwordConfirm,
    phone,
  })
);

const $InviteError = createStore<Nullable<InviteError>>(null, {
  name: 'Errors when invite',
});
$InviteError.on(inviteFx.fail, (_, { error }) => error);
$InviteError.on(setInviteError, (_, error) => error);
$InviteError.reset(merge([inviteFx.done, $inviteForm.updates]));

export const InviteFormConsumer = createStoreConsumer($inviteForm);

inviteFx.use(async ({ password, passwordConfirm, phone, token }) => {
  const response = await Invite.registration({
    password,
    passwordConfirm,
    phone,
    token,
  }).catch((error) => error.response);

  if (response.status === 200 && response?.data?.success) {
    window.location.replace(`${window.location.origin}/login`);
    return Promise.resolve(true);
  }
  if (response.status === 200 && !response?.data?.success) {
    const error = response?.data?.errors[0]?.message;
    if (error) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(error);
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    Promise.reject('Произошла внутренняя ошибка, повторите позднее');
  }

  // TODO: Возможно стоит действительно вместо текстового описания ошибки использовать Error, либо можно заглушить правило prefer-promise-reject-errors
  switch (response.status) {
    case 400:
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Пароли не совпадают');
    case 401:
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Данная пригласительная ссылка не действительна');
    case 404:
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Некорректная пригласительная ссылка');
    case 500:
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Что-то пошло не так, повторите позднее');
    default:
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Произошла внутренняя ошибка, повторите позднее');
  }
});

sample({
  source: $inviteForm,
  clock: onInvite,
  target: inviteFx,
});

export const InviteErrorConsumer = createStoreConsumer($InviteError);
