export class VersionAPI {
  private static readonly prefix: string = 'v';

  public static readonly VERSION1 = `${VersionAPI.prefix}1`;

  public static readonly VERSION10 = `${VersionAPI.prefix}1.0`;

  public static readonly VERSION20 = `${VersionAPI.prefix}2.0`;

  public static readonly VERSION30 = `${VersionAPI.prefix}3.0`;
}
