import { Loading } from '@stecpoint/ui-kit';
import { setToken } from 'components/Authorization/Auth.effects';
import { LOGIN_PAGE, getRoute } from 'constants/pagesNames';
import { useStore } from 'effector-react';
import { useAuth } from 'oidc-react';
import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import PermissionService from 'services/PermissionService';
import { ServiceBase } from 'services/ServiceBase';
import { $Jwt, setJwt } from '../../features/auth/auth';

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const $jwt = useStore($Jwt);
  const oidcAuth = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (oidcAuth.userData?.access_token && $jwt !== oidcAuth.userData.access_token) {
      setJwt(oidcAuth.userData.access_token);
      setToken(oidcAuth.userData.access_token);
      ServiceBase.setAuthToken(oidcAuth.userData.access_token);
      PermissionService.setPermissionAuthToken(oidcAuth.userData.access_token);
    }
  }, [$jwt, oidcAuth.userData?.access_token]);

  useEffect(() => {
    if (!$jwt && !oidcAuth.userData?.access_token && !oidcAuth.isLoading) {
      history.push(getRoute(LOGIN_PAGE));
    }
  }, [$jwt, oidcAuth.userData?.access_token, oidcAuth.isLoading, oidcAuth, history]);

  return <Route {...rest} render={() => ($jwt ? children : <Loading />)} />;
};
