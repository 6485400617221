import { setJwt } from 'components/Auth/features/auth/auth';
import { setToken } from 'components/Authorization/Auth.effects';
import { AuthProviderProps } from 'oidc-react';
import PermissionService from 'services/PermissionService';
import { ServiceBase } from 'services/ServiceBase';
import { AUTHORITY, CLIENT_ID, POST_LOGOUT_REDIRECT_URI, REDIRECT_URI } from './oidcConfigConstants';

export const oidcConfig: AuthProviderProps = {
  authority: AUTHORITY,
  clientId: CLIENT_ID,
  responseType: 'code',
  scope: 'openid profile lahta_profile',
  redirectUri: localStorage.getItem('oidcLogoutPage')
    ? `${REDIRECT_URI}${localStorage.getItem('oidcLogoutPage')}`
    : `${REDIRECT_URI}${window.location.pathname}`,
  postLogoutRedirectUri: POST_LOGOUT_REDIRECT_URI,
  loadUserInfo: false,
  autoSignIn: !window.location.pathname.includes('invite'),
  onSignIn: (user) => {
    if (user) {
      localStorage.removeItem('oidcLogoutPage');
      setJwt(user.access_token);
      setToken(user.access_token);
      ServiceBase.setAuthToken(user.access_token);
      PermissionService.setPermissionAuthToken(user.access_token);
      const url = new URL(window.location.href);
      url.searchParams.delete('code');
      url.searchParams.delete('state');
      window.history.pushState({}, '', url);

      // убрано до согласования
      //  localStorage.setItem(LOGIN_TIMESTAMP, new Date().getTime().toString());
    }
  },
  onSignOut: () => {
    setJwt(null);
    setToken('');
    sessionStorage.clear();

    // убрано до согласования
    // localStorage.removeItem(LOGIN_TIMESTAMP);
  },
};
