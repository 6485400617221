import { API } from 'constants/API';
import { PageContextRequest } from 'models/pageContext/IPageContext';
import { PagedResponse } from 'models/pageContext/PageContextResponse';
import { ServiceBase } from 'services/ServiceBase';
import { VersionAPI } from 'services/versionAPI';
import { Bank, BanksFilter } from '../models/Banks';

export class Banks extends ServiceBase {
  protected static BASE_URL = '/banks';

  protected static options = { baseURL: API.ORGANIZATIONS_API + VersionAPI.VERSION10 };

  public static pageBanks(params: PageContextRequest<BanksFilter>) {
    return this.post<PagedResponse<Bank>>('/page', params, this.options);
  }
}
