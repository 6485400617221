import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import queryString from 'querystring';
import { API } from '../constants/API';
import { ACCESS_DENIED_PAGE, LOGIN_PAGE, getRoute } from '../constants/pagesNames';

enum RequestTypesEnum {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}
export interface ErrorResponse {
  code: number;
  error: string;
  // eslint-disable-next-line camelcase
  error_description:
    | {
        errors: {
          [key: string]: string[];
        };
        type: string;
        title: string;
        status: number;
        detail: string;
      }
    | string;
}

export interface ProcessingResult<T> {
  result: T;
  errors: {
    code: string;
    message: string;
    innerError?: unknown;
  }[];
}

export interface ValidateError {
  [key: string]: string[];
}

export interface ValidateSimpleError {
  key: string;
  message: string;
}

const api: AxiosInstance = axios.create({
  baseURL: API.SSO_API,
  // timeout to response 5 min;
  timeout: 300 * 1000,
});

api.interceptors.response.use(
  (response): AxiosPromise<ProcessingResult<unknown>> => {
    return Promise.resolve(response);
  },
  (error): AxiosPromise<ProcessingResult<unknown>> => {
    if (error.response?.status === 403 && !error.response?.headers?.['content-type']?.includes('text/html')) {
      window.location.replace(getRoute([ACCESS_DENIED_PAGE]));
    }
    if (error.response?.status === 401) {
      sessionStorage.clear();
      window.location.replace(getRoute([LOGIN_PAGE]));
    }

    return Promise.reject(error);
  }
);

export abstract class ServiceBase {
  protected static BASE_URL: string;

  protected static BASE_DOMAIN: string;

  protected static VersionAPI: string = 'v1';

  protected static serviceApi = api;

  protected static abortControllerUrl: Nullable<string> = null;

  protected static abortController = new AbortController();

  protected static lastType: Nullable<RequestTypesEnum> = null;

  public static setAuthToken(token: string) {
    this.serviceApi.defaults.headers.common = {
      ...this.serviceApi.defaults.headers.common,
      Authorization: `bearer ${token}`,
    };
  }

  public static buildUrl(url: string) {
    if (this.BASE_DOMAIN) return `${this.BASE_DOMAIN}${this.VersionAPI}${this.BASE_URL}${url}`;
    return `${this.BASE_URL}${url}`;
  }

  protected static getSignal(currentType: RequestTypesEnum, url: string): AbortSignal {
    if (this.abortController && this.abortControllerUrl === url && this.lastType === currentType) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();
    this.abortControllerUrl = url;
    this.lastType = currentType;
    return this.abortController.signal;
  }

  protected static get<T>(
    url: string,
    data?: Nullable<object>,
    options?: AxiosRequestConfig,
    isStopable = true
  ): AxiosPromise<T> {
    let newUrl: string = url;
    const newOptions = { ...options };
    if (isStopable) {
      newOptions.signal = this.getSignal(RequestTypesEnum.GET, url);
    }

    if (data && Object.keys(data).length) {
      newUrl = `${newUrl}?${queryString.stringify(data)}`;
    }

    return this.serviceApi.get(this.buildUrl(newUrl), newOptions);
  }

  protected static post<T>(
    url: string,
    data?: Nullable<object>,
    options?: AxiosRequestConfig,
    isStopable = true
  ): AxiosPromise<T> {
    const newOptions = { ...options };
    if (isStopable) {
      newOptions.signal = this.getSignal(RequestTypesEnum.POST, url);
    }
    return this.serviceApi.post(this.buildUrl(url), data, newOptions);
  }

  protected static put<T>(
    url: string,
    data?: Nullable<object>,
    options?: AxiosRequestConfig,
    isStopable = true
  ): AxiosPromise<T> {
    const newOptions = { ...options };
    if (isStopable) {
      newOptions.signal = this.getSignal(RequestTypesEnum.PUT, url);
    }
    return this.serviceApi.put(this.buildUrl(url), data, newOptions);
  }

  protected static patch<T>(
    url: string,
    data?: Nullable<object>,
    options?: AxiosRequestConfig,
    isStopable = true
  ): AxiosPromise<T> {
    const newOptions = { ...options };
    if (isStopable) {
      newOptions.signal = this.getSignal(RequestTypesEnum.PATCH, url);
    }
    return this.serviceApi.patch(this.buildUrl(url), data, newOptions);
  }

  protected static delete<T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    data?: Nullable<Object>,
    options?: AxiosRequestConfig,
    disableURLExtends?: boolean,
    isStopable = true
  ): AxiosPromise<T> {
    let newUrl: string = url;
    const newOptions = { ...options };
    if (isStopable) {
      newOptions.signal = this.getSignal(RequestTypesEnum.DELETE, url);
    }

    if (data && !disableURLExtends) {
      newUrl = `${newUrl}?${queryString.stringify(data)}`;
      return this.serviceApi.delete(this.buildUrl(newUrl), newOptions);
    }

    return this.serviceApi.delete(this.buildUrl(newUrl), { ...newOptions, data });
  }
}
