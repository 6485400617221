import { AxiosPromise } from 'axios';
import { API } from 'constants/API';
import { BaseResponseResult, ResponseResult } from 'models/CommonModels';
import { PageContextRequest } from 'models/pageContext/IPageContext';
import { PagedResponse } from 'models/pageContext/PageContextResponse';
import { ServiceBase } from 'services/ServiceBase';
import { VersionAPI } from 'services/versionAPI';
import { ContactsPage, ContactsPageFilter, CreateContactRequest, UpdateContactRequest } from '../models/Contacts';

export class Contacts extends ServiceBase {
  protected static BASE_URL = '/contacts';

  protected static options = { baseURL: API.ORGANIZATIONS_API + VersionAPI.VERSION10 };

  public static addContactPersons(params: CreateContactRequest) {
    return this.post<ResponseResult<number>>('', params, this.options);
  }

  public static contactsPage(
    params: PageContextRequest<ContactsPageFilter>
  ): AxiosPromise<PagedResponse<ContactsPage>> {
    return this.post('/page', params, this.options);
  }

  public static updateContactPersons(params: UpdateContactRequest) {
    return this.put<BaseResponseResult>('', params, this.options);
  }

  public static deleteContact(id: string) {
    return this.delete<BaseResponseResult>(`/${id}`, {}, this.options);
  }
}
