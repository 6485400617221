import axios, { AxiosInstance } from 'axios';
import { Module } from 'components/shared/Access/models';
import { API } from '../constants/API';
import { Role } from './Accounts/RolesService';
import { ServiceBase } from './ServiceBase';
import { VersionAPI } from './versionAPI';

export interface PermissionResources {
  resourceID: number;
  name: string;
  systemName: string;
  description: string;
  actions: PermissionAction[];
}

export interface PermissionAction {
  actionID: number;
  name: string;
}

export enum PermissionActionTypes {
  read = 1,
  delete,
  import,
  search,
  browse,
  edit,
  create,
  download,
  send,
  export,
  upload,
  request,
  check,
  reject,
  accept,
}

export interface PermissionResponse {
  organizationId: number;
  organizationGUID: string;
  userId: number;
  modules: Module[];
  roles: Role[];
  resources: PermissionResources[];
}
const permissionsApi: AxiosInstance = axios.create({
  baseURL: API.PERMISSIONS_API + VersionAPI.VERSION1,
  timeout: 300 * 1000,
});

export default class PermissionService extends ServiceBase {
  protected static BASE_URL = '/permissions';

  protected static VERSION_API = VersionAPI.VERSION1;

  protected static serviceApi = permissionsApi;

  public static setPermissionAuthToken(token: string) {
    this.serviceApi.defaults.headers.common = {
      ...this.serviceApi.defaults.headers.common,
      Authorization: `bearer ${token}`,
    };
  }

  public static getPermissions(userId: number) {
    return this.get<PermissionResponse>(`/${userId}`, {});
  }
}
