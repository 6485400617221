import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export const AuthButton = withStyles((theme) => ({
  root: {
    width: '100%',
    color: '#4e8caf',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 14px rgba(64, 69, 71, 0.03)',
    textShadow: '0 1px 0 #66acd4',
    borderRadius: 0,
    height: '42px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 400,
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&:hover': {
      color: '#003961',
      backgroundColor: '#FFFFFF',
      boxShadow: theme.shadows[2],
    },
  },
}))(Button);
