import { useCallback } from 'react';
import { createEffect, createStore } from 'effector';
import { useStore } from 'effector-react';
import { ShortEntity } from 'models/CommonModels';
import { OrganizationService } from 'services-v2/OrganizationService';
import { $Permission } from '../../Authorization/Permission.effects';

interface OrganizationAccess {
  organizationAccess: boolean;
  isCreator: boolean;
}
interface OrganizationAccessProps {
  organizationTypes?: number[];
  contractCreatorId?: number;
}

export const getOrganizationType = createEffect({
  handler: (id: string | number) =>
    OrganizationService.Organizations.getOrganizationTypeByID(String(id)).then((res) => res.data),
});

export const $OrganizationType = createStore<Nullable<ShortEntity<number>>[]>([]).on(
  getOrganizationType.doneData,
  (_, payload) => payload
);

const useOrganizationAccess = ({
  organizationTypes = [],
  contractCreatorId,
}: OrganizationAccessProps): OrganizationAccess => {
  const $permission = useStore($Permission);
  const $organizationsTypes = useStore($OrganizationType);
  const currentOrganizationId = $permission?.organizationId;

  const checkOrganizationAccess = useCallback(() => {
    let access = false;
    organizationTypes.forEach((id) => {
      if ($organizationsTypes.map((item) => item?.id).includes(id)) {
        access = true;
      }
    });
    return {
      isCreator: contractCreatorId ? contractCreatorId === currentOrganizationId : false,
      organizationAccess: access,
    };
  }, [$organizationsTypes, contractCreatorId, currentOrganizationId, organizationTypes]);

  return checkOrganizationAccess();
};

export default useOrganizationAccess;
