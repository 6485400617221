import { createStore, createEvent, createEffect } from 'effector';
import PermissionService, { PermissionResponse } from '../../services/PermissionService';
import { Role } from '../../services/Accounts/RolesService';
import { checkRolesRights } from './utils';
import { ROLES } from '../../constants/roles';
import { getOrganizationType } from '../shared/Access/useOrganizationAccess';

export const resetPermission = createEvent('Reset permission store');

const storeName = 'Permission store';

interface RoleList {
  [key: string]: boolean;
}
export interface PermissionStore extends PermissionResponse {
  hasRights: RoleList;
}

export const repeatRequest = createEvent();

const convertToHasRights = (currentRoles: Role[]) => {
  if (currentRoles.length) {
    return Object.keys(ROLES).reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: checkRolesRights({ currentRoles, testedRoles: ROLES[currentValue as keyof typeof ROLES] }),
      }),
      {}
    );
  }

  return {};
};

export const fetchPermissionsFx = createEffect({
  handler: async (userId: number): Promise<Nullable<PermissionResponse>> => {
    const { data } = await PermissionService.getPermissions(userId);

    return data ?? null;
  },
});

export const $Permission = createStore<Nullable<PermissionStore>>(null, {
  name: storeName,
});

$Permission
  .on(
    fetchPermissionsFx.done,
    (_, { result }): Nullable<PermissionStore> => {
      if (result) {
        return {
          ...result,
          hasRights: convertToHasRights(result.roles),
        };
      }

      return null;
    }
  )

  .reset(resetPermission);

$Permission.on(repeatRequest, (state) => {
  if (state) {
    fetchPermissionsFx(state.userId);
  }
});
// eslint-disable-next-line no-console
$Permission.updates.watch((permission) => console.log({ permission }));
$Permission.updates.watch(
  (permission) => permission?.organizationId && getOrganizationType(permission?.organizationId)
);
