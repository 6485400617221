export const ROLES = {
  /** Мастер-пользователь	0	NULL */
  SUPER_USER: 'superuser.module.platform',
  /** Администратор ДО	0	NULL */
  ADMIN_DO: 'adm.customer.module.platform',
  /** Поставщик	0	NULL */
  SUPPLIER: 'contractor.module.platform',
  /** Закупщик	0	NULL */
  PURCHASER: 'customer.module.platform',
  /** Агрегатор	0	NULL */
  AGGREGATOR: 'aggregator.module.platform',
  /** Проектант	0	NULL */
  PROEKTANT: 'projectant.module.platform',
  /** Техподдержка	0	NULL */
  TECH_SUPPORT: 'support.module.platform',
  /** Приемщик	0	NULL */
  RECEIVER: 'inspector.module.platform',
  /** Диспетчер потребности	0	NULL */
  DISP_REQ: 'DISP_REQ',
  /** Диспетчер перевозки	0	NULL */
  DISP_ROUTE: 'DISP_ROUTE',
  /** Супервайзер	0	NULL */
  SUPERVISOR: 'SUPERVISOR',
  /** Водитель	0	NULL */
  DRIVER: 'DRIVER',
  /** Логист	0	NULL */
  LOGIST: 'LOGIST',
  /** Исполнитель	0	NULL */
  PERFORMER: 'PERFORMER',
  /** Менеджер по работе с исполнителями	0	NULL */
  PERF_MAN: 'PERF_MAN',
  /** Исполнитель (Биржа)	0	NULL */
  EXECUTOR_STOCK: 'executor.module.bidCenter',
  /** Заказчик	0	NULL */
  CUSTOMER: 'customer.module.bidCenter',
  ADMIN: 'ADMIN',
  /** Администратор логистики	0	NULL */
  ADMIN_LOGIST: 'admin.module.logistic',
  /** Категорийный менеджер	0	NULL */
  CATEGORY_MANAGER: 'manager.module.market',
  /** Сотрудник НСИ	0	NULL */
  EMPLOYEE_NSI: 'EMP_NSI',
  /** Финансовый контроль 1-го уровня	0	NULL */
  FIN_CONTRL_1: 'FIN_CONTRL_1',
  /** Финансовый контроль 2-го уровня	0	NULL */
  FIN_CONTRL_2: 'FIN_CONTRL_2',
  /** 31 Специалист отдела комплектации */
  EQUIPMENT_SPECIALIST: 'picker.module.logistic',
  /** 32 Приёмщик */
  ACCEPTOR: 'receiver.module.logistic',
  /** Администратор (Подрядчик) */
  ADMIN_CONTRACTOR: 'admin.projectcontractor.module.project',
  /** Админ (Проектный офис) */
  ADMIN_OFFICE: 'admin.projectoffice.module.project',
  /** Камеральный проверяющий */
  DESC_AUDITOR: 'desk.audit.module.market',
  /** Сотрудник (Подрядчик) */
  EMPLOYEE_CONTRACTOR: 'employee.projectcontractor.module.project',
  /**  Администратор базы хранения */
  ADMIN_STORAGEBASE: 'administrator.module.storehouse',
  /** Начальник базы хранения */
  CHIEF_STORAGEBASE: 'chief.module.storehouse',
  EXTERNAL_CUSTOMER: 'externalcustomer.module.marketplace',
  /** Оператор платформы */
  PLATFORM_OPERATOR: 'operator.module.platform',
  /** Администратор закупочной деятельности */
  ADMIN_PURCHASE: 'admin.purchase.activities',
};
