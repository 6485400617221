import { API } from 'constants/API';
import { BaseResponseResult } from 'models/CommonModels';
import { ServiceBase } from 'services/ServiceBase';
import { RegistrationParams } from './models';

export class Invite extends ServiceBase {
  protected static BASE_URL = '/account-invite';

  protected static BASE_DOMAIN = String(API.SSO_API);

  protected static VersionAPI = 'v1';

  public static registration({ token, password, passwordConfirm, phone }: RegistrationParams) {
    return this.post<BaseResponseResult>('/registration', { token, password, passwordConfirm, phone });
  }
}
